<template>
  <div>
    <!-- Loading Placeholder -->
    <div
      v-if="isLoading"
      class="loading-placeholder"
    >
      <img :src="props.placeholderUrl">
    </div>

    <!-- Error Placeholder -->
    <div
      v-else-if="hasError"
      class="error-placeholder"
    >
      <img :src="props.placeholderUrl">
    </div>

    <!-- Animation Data Placeholder (if animationData is null) -->
    <div
      v-else-if="!animationData"
      class="animation-data-placeholder"
    >
      <img :src="props.placeholderUrl">
    </div>

    <!-- Lottie Animation (shown when not loading and no error) -->
    <LottieAnimation
      v-else
      ref="anim"
      :animation-data="animationData"
      :auto-play="autoplay"
      :loop="loop"
      class="lottie-obj"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { LottieAnimation } from 'lottie-web-vue';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  placeholderUrl: {
    type: String,
    required: true,
  },
  autoplay:{
    type: Boolean,
    default: true,
  },
  loop:{
    type: Boolean,
    default: true,
  }
});

const animationData = ref(null);
const isLoading = ref(false);
const hasError = ref(false);

const loadAnimationData = () => {
  isLoading.value = true; // Set loading to true when the request starts
  axios.get(props.url)
    .then(response => {
      animationData.value = response.data;
      hasError.value = false; // Reset error state on successful fetch
    })
    .catch(error => {
      console.error('Error fetching the animation data:', error);
      hasError.value = true; // Set error state on failure
    })
    .finally(() => {
      isLoading.value = false; // Set loading to false when the request completes
    });
};

onMounted(loadAnimationData);
</script>

<style>
</style>
