<template>
  <div>
    <blank-screen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <BlogHeader
      :estimate-reading-time="wordpressData.acf_data.estimate_reading_time"
      :title="wordpressData.the_title"
      :sub-title="wordpressData.acf_data.blog_subtitle"
      :header-image="wordpressData.acf_data.header_image"
      :category="wordpressData.blog_tags"
    />
    <BlogContent :content="wordpressData.acf_data.content" />
    <div class="spacing" />
    <ContactUsFooter
      @open-form="handleFormRequest"
    />
    <Footer
      @get-in-touch="showForm = true"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <privacy-policy-banner />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';
import BlogHeader from '../components/blog/BlogHeader.vue';

import BlogContent from '../components/blog/BlogContent.vue';

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const showForm = ref(false);
const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 136px;
  }
}

</style>
