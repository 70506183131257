<template>
  <div style="background-color: #FAFAFA;">
    <blank-screen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacer" />
    <div class="parent-container">
      <div class="sub-container">
        <h1 class="title">
          This is not the page you are looking for.
        </h1>
        <p class="subtitle">
          Meanwhile, check out <a href="/work/">some of the projects we’ve done.</a>
        </p>
        <div class="links-section">
          <a
            href="/about"
            class="link-box"
          >
            <div class="link-content">
              <h2>About us</h2>
              <p>Who we are, and why we do what we do.</p>
            </div>
            <img
              class="arrow"
              :src="$cdn('icons/arrow-right.svg')"
            >
          </a>
          <a
            href="/work"
            class="link-box"
          >
            <div class="link-content">
              <h2>Our work</h2>
              <p>Nothing speaks louder.</p>
            </div>
            <img
              class="arrow"
              :src="$cdn('icons/arrow-right.svg')"
            >
          </a>
          <a
            href="/services"
            class="link-box"
          >
            <div class="link-content">
              <h2>Our services</h2>
              <p>From idea to launch and back again.</p>
            </div>
            <img
              class="arrow"
              :src="$cdn('icons/arrow-right.svg')"
            >
          </a>
          <a
            href="/careers"
            class="link-box"
          >
            <div class="link-content">
              <h2>Join our team</h2>
              <p>Do the best work of your life here.</p>
            </div>
            <img
              class="arrow"
              :src="$cdn('icons/arrow-right.svg')"
            >
          </a>
        </div>
      </div>
    </div>
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import { onMounted, ref} from 'vue';
const showForm = ref(false);
const contactInfo = {
  name:'',
  email:'',
  company:''
};

onMounted(() => {
  // this if 404 page. it would be nice if can notify sentry on what url user has hit 404
});

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};
</script>


<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.spacer {
  height: 30vh;
}

.parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
}

.title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  text-align: center;
}

.subtitle {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 80px;
}

.subtitle a {
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.links-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  max-width: 1024px;
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  flex-grow: 1;
  padding: 16px 24px;
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color change on hover */
}

.link-box:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

.link-box h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
  color: #242424;
}

.link-box p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.link-content{
  flex-grow: 1;
}
.arrow{
  height: 16px;
  width: auto;
  color: #969696;
  margin-left: 12px;
}


@media screen and (max-width: 820px) {

  .spacer {
    height: 164px;
  }

  .sub-container {
    padding: 0 24px;
  }


  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .subtitle a {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .links-section {
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;
  }

  .link-box {
    padding: 16px;
  }

  .link-box h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .link-box p {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
