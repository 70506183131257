<template>
  <div>
    <blank-screen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="openForm"
    />
    <div class="spacing" />
    <CookiePolicy />
    <Footer
      @get-in-touch="openForm"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        @close-form="closeForm"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import CookiePolicy from '../components/cookie_policy/CookiePolicy.vue';
import Footer from '../components/general/Footer.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';

const showForm = ref(false);

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const openForm = () => {
  showForm.value = true;
};
const closeForm = () => {
  showForm.value = false;
};
</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 116px;
  }
}
</style>
