<template>
  <div class="main-container">
    <!-- mobile navbar -->
    <header
      class="header-container light"
    >
      <!-- sidebar menu -->
      <transition name="fade">
        <div
          v-if="showMenu"
          class="mobile-menu-container"
        >
          <div class="menu-header-container">
            <div class="logo">
              <a href="/"><img
                :src="$cdn('logos/alphapod-logo-light.svg')"
                alt="Logo"
                class="header-nav-logo-mobile"
                style="height: 32px;"
              ></a>
            </div>
            <nav>
              <img
                class="header-nav-icon-mobile"
                :src="$cdn('icons/icon_close_navbar.svg')"
                alt="Navigation menu"
                @click="onClickNavIcon"
              >
            </nav>
          </div>
          <div class="menu-list-section">
            <a
              :class="`${currentSection === 'services' ? 'active' : ''}`"
              href="/services/"
            >Services</a>
            <a
              :class="`${currentSection === 'work' ? 'active' : ''}`"
              href="/work/"
            >Work</a>
            <a
              :class="`${currentSection === 'about' ? 'active' : ''}`"
              href="/about/"
            >About</a>
            <a
              :class="`${currentSection === 'careers' ? 'active' : ''}`"
              href="/careers/"
            >Careers</a>
            <a
              href="#"
              @click="getInTouch"
            >Get in Touch</a>
          </div>
          <footer class="nav-footer">
            <p>Build {{ buildNumber }}</p>
            <p>
              © 2024 Alphapod Sdn. Bhd. All Rights Reserved
            </p>
            <p><a href="/privacy-policy/">Privacy Policy</a>|<a href="/cookie-policy/">Cookie Policy</a></p>
          </footer>
        </div>
        <!-- burger menu -->
        <div
          v-else
          class="sub-container"
        >
          <div class="logo">
            <a href="/"><img
              :src="$cdn('logos/alphapod-logo-light.svg')"
              alt="Logo"
              class="header-nav-logo-mobile"
              style="height: 32px;"
            ></a>
          </div>
          <img
            class="header-nav-icon-mobile"
            :src="$cdn('icons/icon_navbar_menu.svg')"
            alt="Navigation menu"
            @click="onClickNavIcon"
          >
        </div>
      </transition>
    </header>
  </div>
</template>

<script setup lang="ts">

import { ref, onMounted, onUnmounted, watch } from 'vue';
import { buildNumber } from '../../support/constant';

defineProps({
  currentSection: {
    type: String,
    default: undefined
  }
});

const emit = defineEmits(['getInTouch']);

const isClick = ref(true);

const onClickNavIcon = () => {
  showMenu.value = !showMenu.value;
};

const showMenu = ref(false);

const hideNavBar = ref(false);

const handleScroll = () => {
  if (showMenu.value) {
    hideNavBar.value = false;
  }else{
    if (window.scrollY < 10) {
      hideNavBar.value = false;
    }else{
      hideNavBar.value = true;
    }
  }
};

onMounted(() => {
  showMenu.value = false;
  isClick.value = false;
  watch(() => showMenu.value, () => {
    if (showMenu.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  });
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const getInTouch = () => {
  showMenu.value = false;
  emit('getInTouch', {});
};

</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    /* transform: translateX(720px); */
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

@keyframes slideFadeOut {
  from {
    opacity: 1;
    /* transform: translateX(0); */
  }
  to {
    opacity: 0;
    /* transform: translateX(720px); */
  }
}

@keyframes slideNavFadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideNavFadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes bounceUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.header-container {
    display: flex;
    position: fixed;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    max-height: 120px;
    min-height: 68px;
    height: 100%;
    width: 100%;
    max-width: 3840px;
    z-index: 999;
    transition: background-color 0.2s ease-in-out;
    transition-delay: 0.2s;
    padding: 0;
    pointer-events: auto;
}

.sub-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 48px;
}

.header-container.light {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  transition: background-color 0.2s ease-in-out;
  cursor: default;
}

.blurred-background{
  backdrop-filter: blur(8px);
  border-radius: 12px;
}

.header-container .logo {
  flex-grow: 1;
}

.header-container .logo img {
    height: 32px;
}

.nav-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}
.nav-menu ul li {
  display: table-cell;
  position: relative;
  padding: 10px 32px;
}
.nav-menu a {
  color: #000;
  font-size: 20px;
  text-decoration: none;
  z-index: 999;

  display: inline-block;
  padding: 4px 0;
  position: relative;
}

.nav-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-menu a.active:after {
  width: 100%;
  left: 0;
}

.nav-menu a:hover:after {
  width: 100%;
  left: 0;
}

.header-nav-logo {
  animation: slideFadeOut 0.3s ease-out forwards;
}

.header-nav-logo.light{
  animation: slideFadeIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.header-nav-icon {
  cursor: pointer;
  /* background-color: rgb(255,255,255,0.2); */
  mix-blend-mode: difference;
  animation: bounceUp 0.3s ease-in-out forwards;
  transition: transform 0.3s ease;
  transition-delay: 0.3s;
}

/* .header-container nav a:hover {
    text-decoration: underline;
    text-underline-offset: 12px;
} */

@media screen and (max-width: 1024px) {

  .mobile-menu-container{
    position: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    justify-content: center;
  }
  .mobile-menu-container a{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .menu-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    height: 68px;
    width: 100%;
  }

  .header-container{
    justify-content: flex-end;
    padding: 12px 24px;
    height: 56px;
  }

  .header-nav-logo,
  .header-nav-logo-mobile{
    max-width: 121.5px;
    height: auto;
  }

  .header-nav-icon,
  .header-nav-icon-mobile{
    width: 32px;
    height: 32px;
    margin: 0;
  }

  .menu-list-section{
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-footer{
    padding-bottom: 24px;
  }

  .nav-footer p,
  .nav-footer a{
    margin: 0 6px;
    align-self: stretch;
    text-align: center;
    color: var(--Neutrals-Nobel, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .mobile-menu-container a.active,
  .header-container nav a.active{
    text-decoration: underline;
    text-underline-offset: 12px;
  }
}

@media screen and (max-width: 820px) {
  .sub-container{
    margin: 0;
  }
}
</style>
