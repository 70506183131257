<template>
  <div class="results-container">
    <div class="results-text">
      <h2>Results</h2>
      <div class="result-description">
        <template
          v-for="(description, n) in props.data.descriptions"
          :key="`paragraph-${n}`"
        >
          <p>
            {{ description.value }}
          </p>
        </template>
      </div>
    </div>
    <div
      v-for="(obj, index) in objs.metrics"
      :key="`stats-${index}`"
      ref="target"
      class="results-stats-top"
      :style="`grid-template-columns: repeat(${obj.length}, 1fr);`"
    >
      <div
        v-for="(metric, i) in obj"
        :key="`stats-${i}`"
        class="stat"
      >
        <!-- label -->
        <h3
          v-if="metric.label_type === 'text'"
          class="stat-title"
        >
          {{ metric.title }}
        </h3>
        <div
          v-else-if="metric.label_type === 'icon'"
          class="stat-icon"
        >
          <img
            :src="`${metric.icon.url}` "
            :alt="`${metric.icon.alt}`"
          >
        </div>
        <!-- number -->
        <number-countdown :value="metric.value" />

        <div class="stat-subtitle">
          {{ metric.subtitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref, computed, onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';

// Define props
const props = defineProps({
  data: {
    type: Object ,
    default: () => ({ metrics: [] })
  }
});

// Chunking function
const chunkArray = (array: any[], chunkSize: number) => {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

// Process function that now includes chunking
const process = (data: any) => {
  if (data.metrics && data.metrics.length) {
    const flatMetrics = data.metrics.map((value: any) => value.metric);
    return {
      metrics: chunkArray(flatMetrics, isMobile.value ? 2 : 3)
    };
  }
  return { metrics: [] };
};

// Computed property to process and reformat the data
const objs = computed(() => process(props.data));
const target = ref(null);

onMounted(() => {
  fadeInEffect('.results-text');
  fadeInEffect('.result-description');
});
</script>

<style scoped>
.results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.results-text {
  max-width: 600px;
  margin-bottom: 120px;
  margin-top: 160px;
}

.results-text h2 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 32px;
}

.results-text p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 32px;
}


.results-stats-top {
  display: grid;
  width: 100%;
  justify-items: center;
  max-width: 720px;
  text-align: start;
}

.stat {
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0 0 0 1px #E0E0E0;
  display: flex;
  flex-direction: column;
}

.results-stats-top .stat{
  width: 100%;
  height: 240px;
}

.stat img{
  width: 24px;
  height: 24px;
}

.stat-icon{
  flex-grow: 1;
}

.stat-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 40px;
  flex-grow: 1;
}

.stat-subtitle {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.stat img{
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 820px) {
  .results-text {
    max-width: 600px;
    margin-bottom: 64px;
    margin-top: 80px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .results-text h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    margin-bottom: 24px;
  }

  .results-text p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 24px;
  }

  .results-stats-top {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 24px;
  }

  .results-stats-top .stat{
    width: 100%;
    height: auto;
  }

  .stat {
    padding: 16px;
  }

  .stat-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
  }

  .stat-subtitle {
    color: var(--Neutrals-Nero, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .stat img{
    margin-bottom: 32px;
  }
}

</style>
