<template>
  <div class="layout-container">
    <div class="sub-container">
      <header class="main-title">
        <h1>Our services</h1>
      </header>
      <section
        v-for="(service, index) in services"
        :id="`${service.ref}`"
        :key="index"
        class="service-section"
      >
        <div class="left-section">
          <img
            :src="$cdn(service.icon)"
            :alt="service.title + ' icon'"
            class="service-icon"
          >
          <h2 class="service-title">
            {{ service.title }}
          </h2>
        </div>
        <div class="right-section">
          <div
            v-for="(detail, detailIndex) in service.details"
            :id="`${ service.ref }`"
            :key="`detail-${detailIndex}`"
          >
            <p class="service-description">
              {{ detail.description }}
            </p>
            <div class="subitem-list">
              <div
                v-if="detail.leftItems && detail.leftItems.length > 0"
                class="service-list"
              >
                <div
                  v-for="(item, itemIndex) in detail.leftItems"
                  :key="`item-${itemIndex}`"
                  class="service-item"
                >
                  <p>{{ item.name }}</p>
                  <div v-if="item.subitems && item.subitems.length > 0">
                    <div
                      v-for="(subitem, subitemIndex) in item.subitems"
                      :key="`subitem-${subitemIndex}`"
                      class="subitem"
                    >
                      {{ subitem }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="detail.rightItems && detail.rightItems.length > 0"
                class="service-list"
              >
                <div
                  v-for="(item, itemIndex) in detail.rightItems"
                  :key="`item-${itemIndex}`"
                  class="service-item"
                >
                  <p>{{ item.name }}</p>
                  <div v-if="item.subitems && item.subitems.length > 0">
                    <div
                      v-for="(subitem, subitemIndex) in item.subitems"
                      :key="`subitem-${subitemIndex}`"
                      class="subitem"
                    >
                      {{ subitem }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>

  </div>
</template>


<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { fadeInEffect } from '../../support/helper';
import services from '../assets/services-value-list.json';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  query: {
    type: String,
    default: '',
  }
});

onMounted(() => {
  watch(() => props.query, (value) => {
    if (value.length > 0) {
      const selector = document.getElementById(value);
      const urlWithoutParams = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, urlWithoutParams);
      gsap.to(window, { duration: 0.5, scrollTo: { y: `#${value}`, offsetY: 0 } , onComplete: () => {
        selector?.scrollIntoView({behavior: 'smooth'});
      }});
    }
  }, {
    immediate: true
  });
  services.forEach(element => {
    fadeInEffect(`#${element.ref}`);
  });
});
</script>

<style scoped>

.layout-container{
  display: flex;
  justify-content: center;
  background-color: #FAFAFA;
}
.sub-container {
  max-width: 1280px;
  padding: 160px 48px;
}

.main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.service-header img{
  width: 32px;
  height: 32px;
}

.service-section {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 32px 0;
  gap: 64px;
}

.left-section {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  min-width: 400px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  align-self: flex-start;
}

.left-section img {
  width: 32px;
  height: 32px;
}

.right-section {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.right-section p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.service-icon {
  width: 50px;
}

.service-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.subitem-list{
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 48px;
}

.service-list {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  list-style:inside;
  padding: 0px 12px;
  width: 50%;
}

.subitem::before{
  content: '- ';
  padding-left: 25px;
}

.service-item p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 6px;
  position: relative;
  padding-left: 20px;
}

.service-item p::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  color: #242424;
}

@media screen and (max-width: 1024px) {
  .sub-container {
    padding: 80px 48px;
  }
  .main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    margin-bottom: 32px;
  }

  .left-section {
    position: relative;
    min-width: 0px;
    top: 0;
  }

  .right-section{
    padding-left: 18px;
  }

  .service-section{
    flex-direction: column;
    gap: 24px;
  }


  .service-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .subitem-list,
  .service-list,
  .right-section p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }
  .service-icon{
    width: 20px;
  }
  .subitem-list{
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
  }

  .service-list {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 820px) {
  .sub-container {
    padding: 80px;
  }

  .service-section{
    gap: 16px;
    padding: 24px 0;
  }

  .service-item p::before {
    font-size: 20px;
  }

  .left-section {
    padding: 8px 0;
    display: flex;
  }

  .left-section img {
    width: 24px;
    height: 24px;
  }

  .subitem-list{
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    padding: 80px 24px;
  }
}
</style>
