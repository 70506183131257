<template>
  <div class="page-three-container">
    <h2 class="form-title">
      Tell us about yourself
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <label
        for="description"
        class="description-label"
      >
        Why are you applying for this position?
      </label>
      <textarea
        id="description"
        v-model="description"
        rows="4"
        placeholder="Brief introduction about yourself
Describe your past/current work experience
Include useful links, e.g. porfolio, GitHub"
        @input="onProjectDescriptionInputChange"
        @paste="onProjectDescriptionInputChange"
      />
      <div
        class="word-count"
        :class="`${(wordsRemaining < 0 || wordsRemaining > 991) ? 'negative' : ''}`"
        :style="`opacity: ${description.length > 0 ? '1' : '0'};`"
      >
        {{ wordsRemaining < 0 ? `${ Math.abs(wordsRemaining) } words exceeded.` : wordsRemaining > 991 ? lessWordWarning : `${ wordsRemaining - 1 } words remaining.` }}
      </div>
      <div class="attachment">
        <div class="attachment-section">
          <label
            for="file-upload"
            class="upload-label"
          >Your CV</label>
          <div
            id="file-picker-container"
            style="cursor: pointer;"
          >
            <p @click="triggerCVFileInputClick">
              {{ cvFilename }}
            </p>
            <img
              v-if="cvFilename == 'Upload PDF'"
              :src="$cdn('icons/icon_paper_clip.svg')"
              alt="Upload"
              @click="triggerCVFileInputClick"
            >
            <img
              v-else
              :src="$cdn('icons/icon_thrash_can.svg')"
              alt="Delete"
              @click="clearCVInputFile"
            >
          </div>
          <input
            ref="cvInput"
            :key="cvKey"
            type="file"
            style="display: none;"
            accept=".pdf"
            @change="handleCVFileChange"
          >
          <span
            v-if="showMaxFileWarning"
            class="file-size-limit"
          >File is too large (max 10MB). Please retry.</span>
        </div>
        <div class="attachment-section">
          <label
            for="file-upload"
            class="upload-label"
          ><p>Supporting material <span>(optional)</span></p></label>
          <div
            id="file-picker-container"
            style="cursor: pointer;"
          >
            <p @click="triggerSupportingFileInputClick">
              {{ supportingFilename }}
            </p>
            <img
              v-if="supportingFilename == 'Select File'"
              :src="$cdn('icons/icon_paper_clip.svg')"
              alt="Upload"
              @click="triggerSupportingFileInputClick"
            >
            <img
              v-else
              :src="$cdn('icons/icon_thrash_can.svg')"
              alt="Delete"
              @click="clearSupportingInputFile"
            >
          </div>
          <input
            ref="supportingInput"
            :key="supportKey"
            type="file"
            style="display: none;"
            accept=".pdf, .doc, .docx, .zip, .rar"
            @change="handleSupportingFileChange"
          >
          <span
            v-if="showMaxFileWarning2"
            class="file-size-limit"
          >File is too large (max 10MB). Please retry.</span>
          <div class="file-instructions">
            If you have multiple files, please ZIP them up first. Max 10MB.
          </div>
        </div>
      </div>

      <div class="button-container">
        <vue-recaptcha
          ref="recaptchaRef"
          :sitekey="sitekey"
          @verify="onVerify"
          @expired="onExpired"
        />
        <button
          type="submit"
          :class="`submit-button ${(!isFormValid || isSendingEmail) ? 'disabled' : ''}`"
          :disabled="(!isFormValid || isSendingEmail)"
        >
          {{ isSendingEmail ? 'Sending...' : 'Complete' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { uuid } from 'vue3-uuid';
import VueRecaptcha from 'vue3-recaptcha2';

const props = defineProps({
  appliedReason: {
    type: String,
    default: ''
  },
  resumeFile: {
    type: HTMLInputElement,
    default: undefined
  },
  supportingFile: {
    type: HTMLInputElement,
    default: undefined
  },
  isSendingEmail: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  if (props.appliedReason.length > 0) {
    description.value = props.appliedReason;
  }
  if (props.resumeFile && props.resumeFile.files && props.resumeFile.files.length > 0) {
    cvFilename.value = props.resumeFile.files[0].name;
  } else {
    cvFilename.value = 'Upload PDF';
  }
  if (props.supportingFile && props.supportingFile.files && props.supportingFile.files.length > 0) {
    supportingFilename.value = props.supportingFile.files[0].name;
  } else {
    supportingFilename.value = 'Select File';
  }
});

const emit = defineEmits(['submit', 'data-changes']);

const description = ref('');
const wordsRemaining = ref(1000);

const cvFilename = ref('Upload PDF');
const cvInput = ref(null);

const supportingFilename = ref('Select File');
const supportingInput = ref(null);

const maxFileSize = 10 * 1024 * 1024; // 10MB

const cvKey = ref(uuid.v4());
const supportKey = ref(uuid.v4());

const showMaxFileWarning = ref(false);
const showMaxFileWarning2 = ref(false);

const recaptchaToken = ref(null);
const recaptchaRef = ref(null);
const sitekey = ref(process.env.RECAPTCHA_V2_SITE_KEY);

const lessWordWarning = ref('Describe this in 10 words or more.');

const triggerCVFileInputClick = () => {
  if (cvInput.value){
    (cvInput.value as HTMLInputElement | null)?.click();
  }
};

const handleCVFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  showMaxFileWarning.value = false;
  if (files && files.length > 0) {
    if (files[0].size > maxFileSize) {
      showMaxFileWarning.value = true;
      return;
    }
    cvFilename.value = files[0].name;
    emit('data-changes', {
      apply_reason: description.value,
      resume_file: cvInput.value ?? undefined
    });
  } else {
    cvFilename.value = 'Upload PDF';
    cvInput.value = null;
  }
};

const triggerSupportingFileInputClick = () => {
  if (supportingInput.value){
    (supportingInput.value as HTMLInputElement | null)?.click();
  }
};

const handleSupportingFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  showMaxFileWarning2.value = false;
  if (files && files.length > 0) {
    if (files[0].size > maxFileSize) {
      showMaxFileWarning2.value = true;
      return;
    }
    supportingFilename.value = files[0].name;
    emit('data-changes', {
      apply_reason: description.value,
      supporting_file: supportingInput.value ?? undefined
    });
  } else {
    supportingFilename.value = 'Select File';
    supportingInput.value = null;
  }
};

const clearCVInputFile = () => {
  // Clear the file input
  if (cvInput.value) {
    cvInput.value = null; // This will clear the selected file
  }
  cvFilename.value = 'Upload PDF'; // Reset the filename
  cvKey.value = uuid.v4();
};

const clearSupportingInputFile = () => {
  // Clear the file input
  if (supportingInput.value) {
    supportingInput.value = null; // This will clear the selected file
  }
  supportingFilename.value = 'Select File'; // Reset the filename
  supportKey.value = uuid.v4();
};

function countWords() {
  const wordsArray = description.value.trim().split(/\s+/);
  return wordsArray.length;
}

const onProjectDescriptionInputChange = () => {
  wordsRemaining.value = 1001 - countWords();
  emit('data-changes', {
    apply_reason: description.value,
  });
};

const wordCountValidity = computed(() => {
  return wordsRemaining.value >= 0;
});

const isFormValid = computed(() => {
  return wordCountValidity.value && countWords() >= 10 && cvFilename.value != 'Upload PDF' && recaptchaToken.value;
});

function onVerify (token: string) {
  recaptchaToken.value = token;
}

function onExpired () {
  recaptchaToken.value = null;
}

const submitForm = () => {
  if (isFormValid.value) {
    emit('submit', {
      apply_reason: description.value,
      resume_file: cvInput.value,
      supporting_file: supportingInput.value,
      recaptchaToken: recaptchaToken.value
    });
  }
};
</script>

<style scoped>

.page-three-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-three-container::-webkit-scrollbar {
  display: none;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.description-label,
.upload-label {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

.upload-label span {
  font-weight: 400;
}

.contact-form textarea {
  width: calc(100% - 6px);
  padding: 16px 24px;
  margin: 12px 3px;
  border: 1px solid #ccc;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 32px;
  border-radius: 12px;
}

.contact-form textarea:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.attachment{
  display: flex;
  gap: 16px;
  flex: 1 1 50%;
}

.word-count {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: right;
  margin-bottom: 48px;
}

.word-count.negative {
  color: #DC4664;
}

.attachment-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

#file-picker-container{
  width: 504px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background: #FFF;
  display: flex;
  padding: 16px 16px 16px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

#file-picker-container p{
  width: 100%;
}

.file-size-limit{
  margin-top: 12px;
  color: #DC4664;
}

.file-instructions {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.button-container{
  margin: 36px 0;
}

.submit-button {
  width: 141px;
  height: 64px;
  background-color: #007AFF;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 64px;
  margin-top: 25px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {

  .form-title {
    color: #242424;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .description-label,
  .upload-label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  .description-label span,
  .upload-label span {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .attachment,
  .attachment-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    flex: 100%;
  }

  .attachment{
    display: flex;
    gap: 16px;
    flex: 1 1 50%;
  }

  .contact-form textarea{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    min-height: 160px;
    border-radius: 10px;
  }

  .word-count {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
  }

  #file-picker-container{
    width: auto;
    height: 48px;
    border-radius: 10px;
  }

  #file-picker-container p{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

  #file-picker-container img {
    width: 24px;
    height: 24px;
  }

  .file-instructions {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 24px;
    margin-bottom: 80px;
    width: 100%;
  }

  .submit-button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    padding: 0;
    width: 121px;
    height: 48px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }
}
</style>
