<template>
  <div :class="`main-container ${isGrayBg ? 'gray-bg' : ''}`">
    <div
      :class="`sub-container ${imageIsLeft ? 'reverse' : ''}` "
    >
      <div
        ref="textRef"
        class="left-section"
      >
        <h2>{{ title }}</h2>
        <div
          v-for="(desc, index) in description"
          :key="`desc-${index}`"
        >
          <div v-if="desc.length > 0">
            <div
              class="career-description"
              v-html="desc"
            />
          </div>
          <br v-else>
        </div>
        <div
          v-if="linkUrl"
          class="link"
        >
          <a :href="linkUrl">{{ linkTitle }}</a>
        </div>
        <div
          v-else
          class="tagline"
        >
          <div
            class="career-link"
            v-html="linkTitle"
          />
        </div>
      </div>
      <div
        ref="lottieRef"
        class="right-section"
      >
        <LottieAnimation
          ref="anim"
          :animation-data="image"
          :auto-play="false"
          :loop="isAutoplay"
          :class="`lottie-obj`"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ref, onMounted } from 'vue';
import { LottieAnimation } from 'lottie-web-vue';
import AnimationStarsJSON from '../lottie/animation_stars.json';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  title:{
    type: String,
    default: 'You have arrived at your destination',
  },
  description:{
    type: Array as () => string[],
    default: () =>['There’s work.. And there’s your life’s work.'],
  },
  image:{
    type: Object,
    default: AnimationStarsJSON,
  },
  imageIsLeft:{
    type: Boolean,
    default: false,
  },
  linkTitle:{
    type: String,
    default:'Read all about our value',
  },
  linkUrl:{
    type: String,
    default: '#',
  },
  isGrayBg:{
    type: Boolean,
    default: false,
  },
  isAutoplay:{
    type: Boolean,
    default: false,
  }
});
const anim = ref<any>(null);
const lottieRef = ref<any>(null);
const textRef = ref<any>(null);

onMounted(() => {
  if (!props.isAutoplay) {
    gsap.to(lottieRef.value, {
      scrollTrigger: {
        trigger: lottieRef.value,
        start: 'center 80%',
        end: 'top 10%',
        scrub: true,
        onUpdate: (self) => {
          const tick = self.progress;
          if (anim.value) {
            anim.value.goToAndStop(tick * 110, true);
          }
        },
      }
    });
  }else{
    anim.value.play();
  }

  gsap.fromTo(textRef.value, {
    opacity: 0,
    y: 100,
  }, {
    opacity: 1,
    y: 0,
    scrollTrigger: {
      trigger: textRef.value,
      start: 'top 90%',
    }
  });

});
</script>

<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-container.gray-bg{
  background-color: #FAFAFA;
}

.sub-container {
  display: flex;
  max-width: 1000px;
  flex-direction: row;
  align-items: center;
  margin: 160px 48px;
}

.left-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.left-section h2 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 116.667% */
  letter-spacing: -1px;
  margin-bottom: 32px;
}

.left-section p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.left-section .link {
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

.left-section .tagline {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.right-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-section.lottie-obj {
  width: 320px;
  height: auto;
}

.sub-container.reverse {
  flex-direction: row-reverse;
  text-align: end;
}

@media screen and (max-width: 1024px) {
  .sub-container.reverse,
  .sub-container {
    flex-direction: column;
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 64px;
  }

  .left-section {
    margin-bottom: 0;
  }

  .right-section img {
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 820px) {
  .sub-container {
    flex-direction: column;
    margin: 64px 80px 0 80px;
  }

  .sub-container.reverse {
    flex-direction: column;
  }
  .left-section {
    margin-bottom:0px;
  }
  .left-section h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
  }

  .left-section p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  .left-section .link {
    color: var(--Primary-Dodger-Blue, #007AFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .left-section .tagline {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .right-section .lottie-obj {
    width: 75vw;
    height: 50vh;
    margin-top: 64px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    flex-direction: column;
    margin: 64px 24px 0 24px;
  }
}
</style>
