export const LINKS = {
  'HOME': '/',
  'SERVICES': '/services',
  'WORK': '/work',
  'ABOUT': '/about',
  'CAREER': '/careers',
  '404': '/404',
  'COOKIE-POLICY': '/cookie-policy',
  'PRIVACY-POLICY': '/privacy-policy',
  'CONTACT': '/contact-us'
};

export const sendEmailUrl = () => {
  return process.env.APP_URL + '/wp-json/contact-us/v1/submit';
};

export const buildNumber = '1.0.9';
