<template>
  <section class="main-container">
    <div class="sub-container">
      <h1 class="section1">
        The confluence of cutting-edge tech and next-gen comms.
      </h1>
      <p class="section2">
        At DNA, we fuse our deep understanding of the consumer psyche with cutting-edge technologies to revolutionise the way audiences interact with brands and products. Our goal is to create immersive, engaging experiences that captivate audiences and drive measurable results.<br><br>
        We believe that digital technology has the power to enhance the human experience, create meaningful impact and increase brand affinity in ways not possible with conventional marketing approaches.<br><br>
        DNA’s approach to every project is agile and data-driven, with a strategy for continuous iteration as the brand and market evolve. This future-ready approach ensures that our clients maintain their competitive edge over time and achieve measurable successes along the way. We do this by developing a deep understanding of our client’s needs, aspirations and challenges and aligning our interests with theirs to ensure that when they win, we win.
      </p>
      <div class="spacer-1" />
      <h4 class="section3">
        Key focus areas
      </h4>
      <p class="section4">
        In an increasingly connected world, we believe that the way to reach audiences and keep them engaged is to captivate their attention with fresh and new digital experiences. To that end, DNA has identified four key focus areas that we believe will define the next decade in marketing communications.
      </p>
    </div>
    <div class="spacer-1" />
    <dna-about-swiper />
  </section>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';

onMounted(()=>{
  fadeInEffect('.section1');
  fadeInEffect('.section2');
  fadeInEffect('.section3');
  fadeInEffect('.section4');
});
</script>


<style scoped>
h1 {
  color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 125% */
  letter-spacing: -3px;
}

h4 {
  color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

p {
  color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-top: 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
}

.spacer-1{
  height: 80px;
}

.spacer-2{
  height: 120px;
}

@media screen and (max-width: 1024px) {
  .sub-container {
    padding: 0 80px;
  }
}

@media screen and (max-width: 820px) {

  h1 {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .main-container {
    padding: 64px 0;
  }

  .sub-container {
    padding: 24px;
  }

  .spacer-1{
    height: 80px;
  }

  .spacer-2{
    height: 60px;
  }
}

</style>
