// about
import AboutCredo from 'wp-content/themes/ap_vue_base_theme/src/components/about/AboutCredo.vue';
import AboutCulture from 'wp-content/themes/ap_vue_base_theme/src/components/about/AboutCulture.vue';
import AboutDescription from 'wp-content/themes/ap_vue_base_theme/src/components/about/AboutDescription.vue';
import AboutHeader from 'wp-content/themes/ap_vue_base_theme/src/components/about/AboutHeader.vue';
import AboutLinkAndDescription from 'wp-content/themes/ap_vue_base_theme/src/components/about/AboutLinkAndDescription.vue';
import BigBrain from 'wp-content/themes/ap_vue_base_theme/src/components/about/BigBrain.vue';

// blog
import BlogHeader from 'wp-content/themes/ap_vue_base_theme/src/components/blog/BlogHeader.vue';
import BlogContent from 'wp-content/themes/ap_vue_base_theme/src/components/blog/BlogContent.vue';
import BlogLandingContent from 'wp-content/themes/ap_vue_base_theme/src/components/blog/BlogLandingContent.vue';
import BlogCard from 'wp-content/themes/ap_vue_base_theme/src/components/blog/BlogCard.vue';

// careers
import CareerHeader from 'wp-content/themes/ap_vue_base_theme/src/components/careers/CareerHeader.vue';
import CareerPath from 'wp-content/themes/ap_vue_base_theme/src/components/careers/CareerPath.vue';
import OpenPosition from 'wp-content/themes/ap_vue_base_theme/src/components/careers/OpenPosition.vue';

// contact us
import ContactUsHolder from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsHolder.vue';
import ContactUsPageOne from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsPageOne.vue';
import ContactUsPageTwo from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsPageTwo.vue';
import ContactUsPageThree from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsPageThree.vue';
import ContactUsPageFour from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsPageFour.vue';
import ContactUsPageFive from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsPageFive.vue';
import ContactUsThankYou from 'wp-content/themes/ap_vue_base_theme/src/components/contact_us_form/ContactUsThankYou.vue';

// Cookie Policy
import CookiePolicy from 'wp-content/themes/ap_vue_base_theme/src/components/cookie_policy/CookiePolicy.vue';

// General
import AppBarDark from 'wp-content/themes/ap_vue_base_theme/src/components/general/AppBarDark.vue';
import AppBarLight from 'wp-content/themes/ap_vue_base_theme/src/components/general/AppBarLight.vue';
import AppBarLightMobile from 'wp-content/themes/ap_vue_base_theme/src/components/general/AppBarLightMobile.vue';
import BlankScreen from 'wp-content/themes/ap_vue_base_theme/src/components/general/BlankScreen.vue';
import ContactUsFooter from 'wp-content/themes/ap_vue_base_theme/src/components/general/ContactUsFooter.vue';
import CookieModal from 'wp-content/themes/ap_vue_base_theme/src/components/general/CookieModal.vue';
import Footer from 'wp-content/themes/ap_vue_base_theme/src/components/general/Footer.vue';
import LottieObj from 'wp-content/themes/ap_vue_base_theme/src/components/general/LottieObj.vue';
import MenuNavButton from 'wp-content/themes/ap_vue_base_theme/src/components/general/MenuNavButton.vue';
import NumberCountdown from 'wp-content/themes/ap_vue_base_theme/src/components/general/NumberCountdown.vue';
import PrivacyPolicyBanner from 'wp-content/themes/ap_vue_base_theme/src/components/general/PrivacyPolicyBanner.vue';
import ShowcaseFooter from 'wp-content/themes/ap_vue_base_theme/src/components/general/ShowcaseFooter.vue';
import Testimonial from 'wp-content/themes/ap_vue_base_theme/src/components/general/Testimonial.vue';
import PhoneNumberDropdown from 'wp-content/themes/ap_vue_base_theme/src/components/general/PhoneNumberDropdown.vue';
import CustomDropdown from 'wp-content/themes/ap_vue_base_theme/src/components/general/CustomDropdown.vue';

// Home
import BigSmallMessage from 'wp-content/themes/ap_vue_base_theme/src/components/home/BigSmallMessage.vue';
import BigSmallMessageNoAnimation from 'wp-content/themes/ap_vue_base_theme/src/components/home/BigSmallMessageNoAnimation.vue';
import BuilderMessage from 'wp-content/themes/ap_vue_base_theme/src/components/home/BuilderMessage.vue';
import ClientIconsDark from 'wp-content/themes/ap_vue_base_theme/src/components/home/ClientIconsDark.vue';
import Hero from 'wp-content/themes/ap_vue_base_theme/src/components/home/Hero.vue';
import WelcomeMessage from 'wp-content/themes/ap_vue_base_theme/src/components/home/WelcomeMessage.vue';

// Job Application
import JobApplicationHolder from 'wp-content/themes/ap_vue_base_theme/src/components/job_application_form/JobApplicationHolder.vue';
import JobApplicationPageOne from 'wp-content/themes/ap_vue_base_theme/src/components/job_application_form/JobApplicationPageOne.vue';
import JobApplicationPageTwo from 'wp-content/themes/ap_vue_base_theme/src/components/job_application_form/JobApplicationPageTwo.vue';
import JobApplicationPageThree from 'wp-content/themes/ap_vue_base_theme/src/components/job_application_form/JobApplicationPageThree.vue';
import JobApplicationThankYou from 'wp-content/themes/ap_vue_base_theme/src/components/job_application_form/JobApplicationThankYou.vue';

// Our Story
import OurStory from 'wp-content/themes/ap_vue_base_theme/src/components/our_story/OurStory.vue';

// Privacy Policy
import PrivacyPolicy from 'wp-content/themes/ap_vue_base_theme/src/components/privacy_policy/PrivacyPolicy.vue';

// Projects
import ProjectChallenge from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectChallenge.vue';
import ProjectConclusion from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectConclusion.vue';
import ProjectDescription from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectDescription.vue';
import ProjectDetails from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectDetails.vue';
import ProjectGallery from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectGallery.vue';
import ProjectHeader from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectHeader.vue';
import ProjectInvolvement from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectInvolvement.vue';
import ProjectResult from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectResult.vue';
import ProjectShowcase from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectShowcase.vue';
import ProjectOrganisationShowcase from 'wp-content/themes/ap_vue_base_theme/src/components/project/ProjectOrganisationShowcase.vue';

// Services
import CredoList from 'wp-content/themes/ap_vue_base_theme/src/components/services/CredoList.vue';
import Methodology from 'wp-content/themes/ap_vue_base_theme/src/components/services/Methodology.vue';
import MethodologyDiagram from 'wp-content/themes/ap_vue_base_theme/src/components/services/MethodologyDiagram.vue';
import ServiceHeader from 'wp-content/themes/ap_vue_base_theme/src/components/services/ServiceHeader.vue';
import ServiceList from 'wp-content/themes/ap_vue_base_theme/src/components/services/ServiceList.vue';

// Works
import ClientIconsLight from 'wp-content/themes/ap_vue_base_theme/src/components/works/ClientIconsLight.vue';
import GalleryModal from 'wp-content/themes/ap_vue_base_theme/src/components/works/GalleryModal.vue';
import WorkHeader from 'wp-content/themes/ap_vue_base_theme/src/components/works/WorkHeader.vue';

// DNA
import DnaAppbar from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-appbar.vue';
import DnaAppbarLight from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-appbar-light.vue';
import DnaAppbarLightMobile from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-appbar-light-mobile.vue';
import DnaHeader from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-header.vue';
import DnaAbout from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-about.vue';
import DnaAboutSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-about-swiper.vue';
import DnaProjectShowcase from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-project-showcase.vue';
import DnaProjectShowcaseCard from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-project-showcase-card.vue';
import DnaProjectHighlight from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-project-highlight.vue';
import DnaAboutVideo from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-about-video.vue';
import DnaGetInTouch from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-get-in-touch.vue';
import DnaContactUsFooter from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-contact-us-footer.vue';
import DnaShowcaseFooter from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-showcase-footer.vue';
import DnaFooter from 'wp-content/themes/ap_vue_base_theme/src/components/dna/dna-footer.vue';

export default {
  'about-credo': AboutCredo,
  'about-culture': AboutCulture,
  'about-description': AboutDescription,
  'about-header': AboutHeader,
  'about-link-and-description': AboutLinkAndDescription,
  'blank-screen': BlankScreen,
  'big-brain': BigBrain,
  'blog-header': BlogHeader,
  'blog-text': BlogContent,
  'blog-landing-text': BlogLandingContent,
  'blog-card': BlogCard,
  'career-header': CareerHeader,
  'career-path': CareerPath,
  'open-position': OpenPosition,
  'contact-us-holder': ContactUsHolder,
  'contact-us-page-one': ContactUsPageOne,
  'contact-us-page-two': ContactUsPageTwo,
  'contact-us-page-three': ContactUsPageThree,
  'contact-us-page-four': ContactUsPageFour,
  'contact-us-page-five': ContactUsPageFive,
  'contact-us-thank-you': ContactUsThankYou,
  'cookie-policy': CookiePolicy,
  'app-bar-dark': AppBarDark,
  'app-bar-light': AppBarLight,
  'app-bar-light-mobile': AppBarLightMobile,
  'contact-us-footer': ContactUsFooter,
  'cookie-modal': CookieModal,
  'ap-footer': Footer,
  'lottie-obj': LottieObj,
  'menu-nav-button': MenuNavButton,
  'number-countdown': NumberCountdown,
  'privacy-policy-banner': PrivacyPolicyBanner,
  'showcase-footer': ShowcaseFooter,
  'testimonial': Testimonial,
  'phone-number-dropdown': PhoneNumberDropdown,
  'custom-dropdown': CustomDropdown,
  'big-small-message': BigSmallMessage,
  'big-small-message-no-animation': BigSmallMessageNoAnimation,
  'builder-message': BuilderMessage,
  'client-icons-dark': ClientIconsDark,
  'hero': Hero,
  'welcome-message': WelcomeMessage,
  'job-application-holder': JobApplicationHolder,
  'job-application-page-one': JobApplicationPageOne,
  'job-application-page-two': JobApplicationPageTwo,
  'job-application-page-three': JobApplicationPageThree,
  'job-application-thank-you': JobApplicationThankYou,
  'our-story': OurStory,
  'privacy-policy': PrivacyPolicy,
  'project-challenge': ProjectChallenge,
  'project-conclusion': ProjectConclusion,
  'project-description': ProjectDescription,
  'project-details': ProjectDetails,
  'project-gallery': ProjectGallery,
  'project-header': ProjectHeader,
  'project-involvement': ProjectInvolvement,
  'project-result': ProjectResult,
  'project-showcase': ProjectShowcase,
  'project-organisation-showcase': ProjectOrganisationShowcase,
  'credo-list': CredoList,
  'methodology': Methodology,
  'methodology-diagram': MethodologyDiagram,
  'service-header': ServiceHeader,
  'service-list': ServiceList,
  'client-icons-light': ClientIconsLight,
  'gallery-modal': GalleryModal,
  'work-header': WorkHeader,

  // DNA
  'dna-appbar': DnaAppbar,
  'dna-appbar-light': DnaAppbarLight,
  'dna-appbar-light-mobile': DnaAppbarLightMobile,
  'dna-header': DnaHeader,
  'dna-about': DnaAbout,
  'dna-about-swiper': DnaAboutSwiper,
  'dna-project-showcase': DnaProjectShowcase,
  'dna-project-showcase-card': DnaProjectShowcaseCard,
  'dna-project-highlight': DnaProjectHighlight,
  'dna-about-video': DnaAboutVideo,
  'dna-get-in-touch': DnaGetInTouch,
  'dna-contact-us-footer': DnaContactUsFooter,
  'dna-showcase-footer': DnaShowcaseFooter,
  'dna-footer': DnaFooter,
};
