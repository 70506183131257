<template>
  <section class="main-container">
    <div class="sub-container">
      <h1> DNA </h1>
      <h3> The confluence of cutting-edge tech and communications. </h3>
    </div>
    <video
      :src="$cdn('https://assets.dstnct.co/themes/dstnctv6/videos/1.SHINE-Festival-2018-TVC.mp4')"
      autoplay
      loop
      muted
      playsinline
      class="responsive-video"
    >
      Your browser does not support the video tag.
    </video>

  </section>
</template>

<script setup lang="ts">

import { computed } from 'vue';

interface Category {
  name: string,
  slug: string
}

const props = defineProps({

  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  headerImage: {
    type: Object,
    default: undefined
  },
  category: {
    type: Array as () => Category[],
    default: undefined
  }
});

const baseUrl = process.env.APP_URL;

const formattedCategories = computed(() => {
  if (!props.category) return 'Undefined';
  return props.category
    .map((obj) => `<a href="${baseUrl}/blog?tag=${obj.slug}" class="category-link">${obj.name ?? 'Undefined'}</a>`)
    .join(' | ');
});

</script>

<style scoped>

.hidden {
  display: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  padding: 0 48px;
}

h1 {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -3px;
  text-align: left;
  padding-bottom: 32px;
}

h3 {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.main-container video {
  object-fit: cover;
  width: 100%;
  margin-top: 80px;
  aspect-ratio: 12/5;
}

@media screen and (max-width: 820px) {
  .sub-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 24px;
  }

  h1 {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -2px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .main-container video {
    object-fit: cover;
    height: 260px;
    width: 100%;
    margin-top: 64px;
    aspect-ratio: 0;
  }
}

</style>
