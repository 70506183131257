<template>
  <div
    ref="mainContainer"
    :class="`main-container ${darkContainer ? 'dark-background' : ''}` "
  >
    <div
      id="subContainer"
      class="sub-container"
    >
      <div
        :class="`big-small-title ${darkContainer ? 'dark-background' : ''}`"
      >
        <h1>Big, small, and everything in between.</h1>
      </div>
      <div
        ref="target"
        class="circle-container"
      >
        <svg
          class="small-circle"
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

        <svg
          class="medium-circle"
          viewBox="0 0 191 191"
        >
          <circle
            cx="95.5"
            cy="95.5"
            r="90"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

        <svg
          class="large-circle"
          viewBox="0 0 366 366"
        >
          <circle
            cx="183"
            cy="183"
            r="180"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

      </div>
    </div>

  </div>
</template>


<script setup lang="ts">
import { ref, onMounted } from 'vue';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
const target = ref(null);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const mainContainer = ref(null);
const darkContainer = ref(false);

const isEnd = ref(false);

const emit = defineEmits(['end-animation']);

function animationOnScroll(){
  let endTrigger = 'bottom bottom';
  let prevProgress = 0;
  ScrollTrigger.create({
    trigger: '#subContainer',
    endTrigger: mainContainer.value,
    start: 'top top',
    end: endTrigger,
    toggleActions: 'play none none none',
    pin: true,
    onUpdate: (self) => {
      if (self.progress > prevProgress) {
        prevProgress = self.progress;
        if (self.progress >= 0.95) {
          if (isEnd.value) {
            return;
          }
          isEnd.value = true;
          gsap.to(window, {
            scrollTo: { y: '#subContainer', offsetY: 0 },
            duration: 0.02,
          });
          emit('end-animation');
        }else if (self.progress > 0.8) {
          darkContainer.value = true;
          gsap.to('.small-circle circle, .large-circle circle, .medium-circle circle', {
            attr: { fill: '#fff' },
            ease: 'bounce'
          });
        } else if (self.progress > 0.6) {
          // Target .medium-circle for color change
          gsap.to('.medium-circle circle', {
            attr: { fill: '#000000' },
          });
          addFrame('.medium-circle');
        } else if (self.progress > 0.4) {
          // Target .large-circle for color change
          gsap.to('.large-circle circle', {
            attr: { fill: '#000000' },
          });
          addFrame('.large-circle');
        } else if (self.progress > 0.2) {
          // Target .small-circle for color change
          gsap.to('.small-circle circle', {
            attr: { fill: '#000000' },
          });
          addFrame('.small-circle');
        }
      }
    }
  });
}

onMounted(() => { animationOnScroll(); });

function addFrame(selector: string, className: string = 'frame') {
  const frame = document.querySelector(selector);
  if (frame) {
    frame.classList.add(className);
  }
}
</script>


<style scoped>
.main-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 3000px;
  min-height: fit-content;
  background-color: #fff;
  transition: black 0.5s ease-in ;
}

.sub-container{
  background-color: transparent;
  max-width: 1000px;
  min-height: fit-content;
  padding: 160px 24px;
  transition: black 0.5s ease-in ;
}
.big-small-title{
  color: #242424;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
}

@keyframes bounceFillColor {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

@keyframes spread {
  from {
    background-size: 0% 0%;
  }
  to {
    background-size: 500% 500%;
  }
}

.main-container.dark-background {
  /* Initial background is white or whatever the "light" state is */
  background-color: #000; /* Fallback color */

  /* Create a radial gradient that transitions from black in the center */
  /* background-image: radial-gradient(circle, black, black 50%, transparent 50%); */
  background-position: center;
  background-repeat: no-repeat;

  /* Start with the gradient size that covers no area */
  background-size: 0% 0%;

  /* Ensure the content text color contrasts with the dark background when the animation completes */
  color: white;

  /* Apply the animation */
  animation: spread 0.5s ease-in forwards;
}

.big-small-title.dark-background{
  color: #fff;
  transition: color 0.5s ease-in ;
}

.circle-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: black 0.5s ease-in;
}

.small-circle {
  height: 24px;
  width: 24px;
}

.small-circle.frame {
  animation: bounceFillColor 0.5s ease-in-out;
}

.medium-circle {
  height: 191px;
  width: 191px;
}

.medium-circle.frame {
  animation: bounceFillColor 0.5s ease-in-out;
}

.large-circle {
  height: 366px;
  width: 366px;
}

.large-circle.frame {
  animation: bounceFillColor 0.5s ease-in-out;
}

@media screen and (max-width: 480px) {

  .main-container{
    height: 2500px;
  }
  .sub-container{
    background-color: transparent;
    padding: 80px 24px;
    transition: black 0.5s ease-in ;
  }
  .big-small-title{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }

  .circle-container {
    padding-top: 64px;
  }

  .small-circle {
    height: 12px;
    width: 12px;
  }

  .medium-circle {
    height: 49px;
    width: 49px;
  }

  .large-circle {
    height: 130px;
    width: 130px;
  }
}

</style>
