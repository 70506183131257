<template>
  <div
    id="about-credo"
    class="layout-container"
  >
    <div class="sub-container">
      <div class="main-title-container">
        <div
          v-for="(mainTitle, index) in uniqueCategories"
          :key="`title-${index}`"
          :class="`main-title ${selectedCategory === mainTitle ? 'active' : ''}`"
          @click="selectedCategory = mainTitle"
        >
          <h1>{{ mainTitle }}</h1>
        </div>
      </div>
      <section
        v-for="(service, index) in filteredServices"
        :key="`${service.title}-${index}`"
        class="service-section"
      >
        <div class="left-section">
          <h4 v-if="service.subtitle.length > 0">
            {{ service.subtitle }}
          </h4>
          <h2
            v-if="service.title.length > 0"
            class="service-title"
          >
            {{ service.title }}
          </h2>
          <LottieObj
            :url="$cdn(service.icon)"
            :placeholder-url="$cdn('credos/code_far.svg')"
            class="lottie-obj"
          />
        </div>
        <div class="right-section">
          <div
            v-for="(detail, detailIndex) in service.details"
            :id="`${detail.reference}`"
            :key="`detail-${detailIndex}`"
            :class="{'service-detail': true, 'first-detail': detailIndex === 0}"
          >
            <h3>
              {{ detail.title }}
            </h3>
            <p
              v-for="(description, descriptionIndex) in detail.description"
              :key="`description-${descriptionIndex}`"
              class="service-description"
            >
              {{ description }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref, computed, onMounted, nextTick } from 'vue';
import LottieObj from '../general/LottieObj.vue';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

import services from '../assets/about-value-credos.json';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  query: {
    type: String,
    default: ''
  }
});

onMounted(() => {
  if (props.query.length > 0) {
    nextTick(() => {
      const urlWithoutParams = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, urlWithoutParams);
      const element = document.getElementById('about-credo');
      gsap.to(window, {
        scrollTo: {
          y: 7500,
          autoKill: false
        },
        duration: 1 ,
        onComplete: () => {
          element?.scrollIntoView({behavior: 'smooth'});

        }
      });
    });
  }
});

const uniqueCategories = computed(() => {
  // Create a new Set to store unique categories
  const categoriesSet = new Set();

  // Iterate over the services array and add each category to the Set
  services.forEach(service => {
    categoriesSet.add(service.category);
  });

  // Convert the Set back to an array to get a list of unique categories
  return Array.from(categoriesSet);
});

const selectedCategory = ref(uniqueCategories.value[0]);

// Computed property to filter services based on the selected category
const filteredServices = computed(() => {
  // If no category is selected, return all services
  if (!selectedCategory.value) {
    return services;
  }
  // Return services that match the selected category
  return services.filter(service => service.category === selectedCategory.value);
});

</script>

<style scoped>

.layout-container {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 160px;
  height: 100%;
  width: 100%;
}

.sub-container{
  max-width: 1280px;
  margin: 0 24px;
}

.main-title-container{
  display: flex;
  flex-direction: row;
  padding-top: 160px;
  gap: 64px;
}
.main-title {
  color: #C8C8C8;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  padding-bottom: 24px;
  z-index: 10;
  transition: color 0.3s ease;
}

.main-title:hover {
  cursor: pointer;
  color: #969696;
}

.main-title.active {
  color: #242424;
  transition: color 0.3s ease-in;
}

.service-section {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-top: 1px solid #ccc;
  padding: 32px 0;
}

.left-section {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  width: 426px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
}

.left-section .lottie-obj{
  max-width: 240px;
}

.left-section h4 {
  color: #969696;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.left-section h2 {
  color: #242424;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.left-section img {
  width: 240px;
  height: auto;
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.service-detail:not(.first-detail) {
  border-top: 1px solid #ccc;
  padding-top: 32px;
}

.right-section h3{
  align-self: stretch;
  color: #242424;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.right-section p{
  color: #242424;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {

  .layout-container{
    padding-bottom: 48px;
  }

  .sub-container{
    margin: 0 80px;
  }

  .service-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    padding: 32px 0;
    gap: 24px;
  }

  .service-detail:not(.first-detail) {
    border-top: none;
    padding-top: 0;
  }

  .left-section {
    position: relative;
    top: 0;
    min-width: 0px;
    width: 100%;
    align-items: center;
  }
  .main-title-container{
    gap: 13px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-top: 80px;
  }
  .main-title {
    width: inline-flex;
    white-space: nowrap;
    color: var(--Neutrals-Very-Light-Grey, #C8C8C8);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px; /* 121.875% */
    letter-spacing: -0.75px;
    gap: 13px;
  }

  .main-title.active{
    color: var(--Neutrals-Nero, #242424);
    transition: color 0.3s ease-in;
  }

  .left-section h4 {
    align-self: stretch;
    color: var(--Neutrals-Nobel, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .left-section h2 {
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .left-section img {
    max-width: 193px;
    margin-top: 55px;
  }

  .right-section h3{
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .right-section p{
    color: var(--Neutrals-Nero, #242424);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }

}

@media screen and (max-width: 480px) {
  .sub-container{
    margin: 0 24px;
  }
}
</style>
