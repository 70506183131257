<template>
  <div class="main-container">
    <div class="project-description">
      <h1>{{ obj.title }}</h1>
      <p
        v-for="(paragraph, index) in obj.description"
        :key="index"
      >
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
});

onMounted(() => {
  fadeInEffect('.project-description h1');
  fadeInEffect('.project-description p');
});

const process = (data: any) => {
  return {
    title: data.title,
    description: data.details.map((value: any) => value.detail)
  };
};

const obj = computed(() => process(props.data));
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
}
.project-description {
  margin: 160px 48px;
  max-width: 720px;
  padding: 0 24px;
  font-family: 'Inter', sans-serif;
}

.project-description h1 {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  text-align: left;
  padding-bottom: 32px;
}

.project-description p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.project-description p:not(:last-child) {
  margin-bottom: 32px;
}

@media screen and (max-width: 820px) {

  .main-container {
    margin: 64px 0;
  }

  .project-description {
    margin: 80px 0;
  }

  .project-description h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    padding-bottom: 32px;;
  }

  .project-description p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .project-description {
    padding: 0 40px;
  }
}
</style>
