<template>
  <section class="main-container">
    <!--  -->
    <div class="sub-container">
      <h1>
        Get in touch
      </h1>
      <div class="grid-container">
        <div class="left-container">
          <img :src="$cdn('dna/dna-get-in-touch-sg.jpg')">
          <div class="detail-container">
            <div>
              <h4>Singapore</h4>
            </div>
            <div>
              <p>231 Mountbatten Road<br>
                Block C Level 3<br>
                Singapore 397999.</p>
            </div>
          </div>
        </div>
        <div class="right-container">
          <img :src="$cdn('dna/dna-get-in-touch-my.jpg')">
          <div class="detail-container">
            <div>
              <h4>Kuala Lumpur</h4>
            </div>
            <div>
              <p>E-03-08, Sunway Geo Avenue 2<br>
                Jalan Lagoon Selatan, Bandar Sunway<br>
                47500 Subang Jaya, Selangor, Malaysia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script setup lang="ts">
</script>

<style scoped>

h1 {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 125% */
  letter-spacing: -3px;
  z-index: 10;
}

h4 {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.25px;
}

p {
  color: #FAFAFA;
  text-align: right;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 80px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-bottom: 136px;
  align-items: center;
  gap: 64px;
}

.detail-container{
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.left-container, .right-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

@media screen and (max-width: 820px) {

  h1 {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    text-align: start;
  }

  .main-container {
    padding: 64px 24px;
  }

  .grid-container {
    grid-template-columns: 1fr;
    gap: 48px;
  }

  .detail-container{
    grid-template-columns: 1fr;
  }

  .left-container, .right-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

}
</style>
