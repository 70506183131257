<template>
  <div class="main-container">
    <div :class="`layout-container ${!showBanner ? 'hide' : ''}`">
      <div class="left-section">
        <h3>Cookie consent</h3>
        <p>This website uses cookies to enhance your browsing experience and provide personalized content. <a href="/cookie-policy/">Learn more</a></p>
      </div>
      <div class="right-section">
        <button
          class="btn-secondary"
          @click="onClickStrictlyNecessary"
        >
          Manage my preferences
        </button>
        <button
          class="btn-primary"
          @click="onClickAcceptAll"
        >
          Accept all
        </button>
      </div>
    </div>
    <transition name="fade">
      <CookieModal
        v-if="showCookieModal"
        @close-cookie-modal="handleModalClick"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">

import { onMounted, ref, watch } from 'vue';
import CookieModal from './CookieModal.vue';

const showBanner = ref(false);
const showCookieModal = ref(false);

const emit = defineEmits(['open-cookie-modal']);

onMounted(() => {
  watch(() => localStorage.getItem('cookiesAccepted'), (value) => {
    if (value === 'true') {
      showBanner.value = false;
    }else{
      showBanner.value = true;
    }
  }, { immediate: true });

  watch(() => showCookieModal.value, (value) => {
    if (value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, { immediate: true });
});

function handleModalClick(data: any) {
  showCookieModal.value = false;
  if (data != null) {
    const cookie = {
      essential_cookie: data[0].state,
      performance_cookie: data[1].state,
      analytics_cookie: data[2].state,
      social_network_cookie: data[3].state,
    };
    // Serialize the `cookie` object to a string before storing
    localStorage.setItem('essential_consent', cookie.essential_cookie);
    localStorage.setItem('performance_consent', cookie.performance_cookie);
    localStorage.setItem('analytics_consent', cookie.analytics_cookie);
    localStorage.setItem('social_network_consent', cookie.social_network_cookie);

    localStorage.setItem('cookiesAccepted', 'true');
  }
}

const onClickStrictlyNecessary = () => {
  showCookieModal.value = true;
  showBanner.value = false;
};

const onClickAcceptAll = () => {
  localStorage.setItem('essential_consent', 'true');
  localStorage.setItem('performance_consent', 'true');
  localStorage.setItem('analytics_consent', 'true');
  localStorage.setItem('social_network_consent', 'true');

  localStorage.setItem('cookiesAccepted', 'true');
  showBanner.value = false;
};

</script>

<style scoped>

.main-container {
  z-index: 9999;
}

.layout-container {
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding: 24px 64px;
  backdrop-filter: blur(8px);
  border-top: #DCDCDC 1px solid;
  z-index: 999;
}

.hide {
  display: none;
}

.left-section {
  display: flex;
  flex-direction: column;
}

.left-section h3 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.left-section p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.left-section a {
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

.right-section {
  display: flex;
  gap: 16px;
  flex-direction: row;
}
.btn-primary {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background-color: #007AFF;
  color: white;
}

.btn-secondary {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background: #FFF;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .layout-container {
    flex-direction: column;
    padding: 32px 24px;
  }

  .left-section {
    margin-bottom: 64px;
  }

  .right-section {
    flex-direction: column-reverse;
    gap: 12px;
  }

}
</style>
