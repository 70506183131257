<template>
  <div class="page-five-container">
    <h2 class="form-title">
      Describe your project in detail
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <div class="project-description-form">
        <label
          for="description"
          class="description-label"
        >
          Provide a detailed description of your project so we can better assist you
        </label>
        <textarea
          id="description"
          v-model="description"
          rows="3"
          placeholder="What is the problem your project is trying to solve?
Who are your target users?
Describe the features, product roadmap"
          class="project-description-detail"
          @input="onProjectDescriptionInputChange"
          @paste="onProjectDescriptionInputChange"
        />
        <div
          class="word-count"
          :class="`${(wordsRemaining < 0 || wordsRemaining >= 242) ? 'negative' : ''}`"
          :style="`opacity: ${description.length > 0 ? '1' : '0'};`"
        >
          {{ wordsRemaining < 0 ? `${ Math.abs(wordsRemaining) } words exceeded.` : wordsRemaining >= 242 ? lessWordWarning : `${ wordsRemaining - 1 } words remaining.` }}
        </div>
      </div>
      <div class="project-description-form">
        <label
          for="description"
          class="description-label"
        >
          Products or services you would like to emulate <span>(optional)</span>
        </label>
        <textarea
          id="description"
          v-model="description2"
          rows="3"
          placeholder="Who are the market incumbents?
Who are your competitors?
What do you like about them?"
          @input="onProjectDescription2InputChange"
          @paste="onProjectDescription2InputChange"
        />
        <div
          class="word-count"
          :class="`${(wordsRemaining2 < 0 || wordsRemaining2 >= 242) ? 'negative' : ''}`"
          :style="`opacity: ${description2.length > 0 ? '1' : '0'};`"
        >
          {{ wordsRemaining2 < 0 ? `${ Math.abs(wordsRemaining2) } words exceeded.` : wordsRemaining2 >= 242 ? lessWordWarning : `${ wordsRemaining2 - 1} words remaining.` }}
        </div>
      </div>
      <div class="attachment-section">
        <label
          for="file-upload"
          class="upload-label"
        >
          Upload an attachment <span>(optional)</span></label>
        <div
          id="file-picker-container"
          style="cursor: pointer;"
        >
          <p @click="triggerFileInputClick">
            {{ filename }}
          </p>
          <img
            v-if="filename == 'Upload PDF'"
            :src="$cdn('icons/icon_paper_clip.svg')"
            alt="Upload"
            @click="triggerFileInputClick"
          >
          <img
            v-else
            :src="$cdn('icons/icon_thrash_can.svg')"
            alt="Delete"
            @click="clearFile"
          >
        </div>
        <input
          ref="fileInput"
          :key="attachmentKey"
          type="file"
          style="display: none;"
          accept=".pdf, .doc, .docx, .zip, .rar"
          @change="handleFileChange"
        >
        <span
          v-if="showMaxFileWarning"
          class="file-size-limit"
        >File is too large (max 10MB). Please retry.</span>
        <div class="file-instructions">
          If you have multiple files, please ZIP them up first. Max 10MB.
        </div>
      </div>
      <div class="button-container">
        <vue-recaptcha
          ref="recaptchaRef"
          :sitekey="sitekey"
          @verify="onVerify"
          @expired="onExpired"
        />
        <button
          type="submit"
          :class="`submit-button ${(!isFormValid || isSendingEmail) ? 'disabled' : ''}`"
          :disabled="!isFormValid || isSendingEmail"
        >
          {{ isSendingEmail ? 'Sending...' : 'Complete' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { uuid } from 'vue3-uuid';
import VueRecaptcha from 'vue3-recaptcha2';

const props = defineProps({
  projectDescription: {
    type: String,
    default: ''
  },
  projectDescription2: {
    type: String,
    default: ''
  },
  projectFile: {
    type: HTMLInputElement,
    default: undefined
  },
  isSendingEmail: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  if (props.projectDescription.length > 0) {
    description.value = props.projectDescription;
    setTimeout(() => {
      onProjectDescriptionInputChange();
    },100);
  }
  if (props.projectDescription2.length > 0) {
    description2.value = props.projectDescription2;
    setTimeout(() => {
      onProjectDescription2InputChange();
    },100);
  }
  if (props.projectFile && props.projectFile.files) {
    filename.value = props.projectFile.files[0].name;
  }
});

const emit = defineEmits(['submit', 'data-changes']);

const description = ref('');
const wordsRemaining = ref(250);

const description2 = ref('');
const wordsRemaining2 = ref(250);

const filename = ref('Upload PDF');
const fileInput = ref(null);
const maxFileSize = 10 * 1024 * 1024; // 10MB
const showMaxFileWarning = ref(false);

const attachmentKey = ref(uuid.v4());

const lessWordWarning = ref('Describe this in 10 words or more.');

const recaptchaToken = ref(null);
const recaptchaRef = ref(null);
const sitekey = ref(process.env.RECAPTCHA_V2_SITE_KEY);
const triggerFileInputClick = () => {
  if (fileInput.value){
    (fileInput.value as HTMLInputElement | null)?.click();
  }
};

const handleFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  showMaxFileWarning.value = false;
  if (files && files.length > 0) {
    if (files[0].size > maxFileSize) {
      showMaxFileWarning.value = true;
      return;
    }
    filename.value = files[0].name;
    emit('data-changes', {
      project_description: description.value,
      project_file: fileInput.value ?? undefined
    });
  } else {
    filename.value = 'Upload PDF';
    fileInput.value = null;
  }
};

function countWords(text: string = description.value) {
  const wordsArray = text.trim().split(/\s+/);
  return wordsArray.length;
}

const onProjectDescriptionInputChange = () => {
  wordsRemaining.value = 251 - countWords();
  emit('data-changes', {
    project_description: description.value,
  });
};

const onProjectDescription2InputChange = () => {
  wordsRemaining2.value = 251 - countWords(description2.value);
  emit('data-changes', {
    project_description2: description2.value,
  });
};

const wordCountValidity = computed(() => {
  return wordsRemaining.value >= 0 ;
});

const isFormValid = computed(() => {
  return wordCountValidity.value && countWords() >= 10 && (description2.value.length === 0 || wordsRemaining2.value <= 240) && recaptchaToken.value;
});

function onVerify (token: string) {
  recaptchaToken.value = token;
}

function onExpired () {
  recaptchaToken.value = null;
}

const submitForm = () => {
  if (isFormValid.value) {
    emit('submit', {
      description: description.value,
      description2: description2.value,
      file: fileInput.value,
      recaptchaToken: recaptchaToken.value
    });
  }
};

const clearFile = () => {
  showMaxFileWarning.value = false;
  // Clear the file input
  if (fileInput.value) {
    fileInput.value = null; // This will clear the selected file
  }
  filename.value = 'Upload PDF'; // Reset the filename
  attachmentKey.value = uuid.v4();
};
</script>

<style scoped>

.page-five-container {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-five-container::-webkit-scrollbar {
  display: none;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.description-label,
.upload-label {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

.description-label span, .upload-label span {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.contact-form textarea {
  width: calc(100% - 6px);
  padding: 16px 24px;
  margin: 12px 3px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-family: 'Inter' sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left
}

.contact-form textarea:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.word-count {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: right;
  margin-bottom: 48px;
}

.file-size-limit{
  margin-top: 12px;
  color: #DC4664;
}

.word-count.negative {
  color: #DC4664;
}

.attachment-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
}

#file-picker-container{
  width: 504px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background: #FFF;
  display: flex;
  padding: 16px 16px 16px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

#file-picker-container p{
  width: 100%;
}

.file-instructions {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 25px 0;
  width: 141px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {

  .form-title {
    color: #242424;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .description-label,
  .upload-label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  .description-label span, .upload-label span {
    font-size: 16px;
    line-height: 24px;
  }

  .attachment-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }

  .project-description-detail{
    min-height: 160px;
  }

  .contact-form textarea{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    border-radius: 10px;
  }

  .word-count {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
  }

  #file-picker-container{
    width: 100%;
    height: 48px;
    border-radius: 10px;
  }

  #file-picker-container p{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

  .file-instructions {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }

  .submit-button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    padding: 12px 24px;
    width: 121px;
    height: 48px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    width: 121px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }
}

</style>
