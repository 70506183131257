<template>
  <section class="main-container">
    <video
      :src="$cdn('https://res.cloudinary.com/alphapod/video/upload/v1723515258/alphapod_website/website_2024/assets/video/ap_dna_helix.av1.mp4')"
      autoplay
      loop
      muted
      playsinline
      class="responsive-video"
    >
      Your browser does not support the video tag.
    </video>
    <div class="gradient" />
    <div class="content-container">
      <h4 class="animate-about-section1">
        “Alphapod x DSTNCT”
      </h4>
      <p class="animate-about-section2">
        This section talks about the coming together of Alphapod and DSTNCT to create DNA.
      </p>
      <img
        class="animate-about-section3"
        :src="$cdn('dna/dna-about-venn-diagram.png')"
      >
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';


onMounted(()=>{
  fadeInEffect('.animate-about-section1');
  fadeInEffect('.animate-about-section2');
  fadeInEffect('.animate-about-section3');
});

</script>

<style scoped>
h4 {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.25px;
  margin-bottom: 32px;
}

p {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  margin-bottom: 82px;
}

.main-container {
  max-width: 3840px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; /* Take full viewport height */
  overflow: hidden; /* Hide overflow to ensure the video covers */
}

.gradient {
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 105%;

  background: linear-gradient(to top right, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 90%),
              linear-gradient(to bottom left, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 90%);
  z-index: 0;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Force the video to follow main container's height */
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  z-index: 1;
  color: white;
  text-align: left;
  padding: 129px 0;
}

@media screen and (max-width: 1024px) {
  .content-container {
    padding: 80px;
  }
}

@media (max-width: 820px) {

  h4 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .main-container {
    height: 100%; /* Allow height to be based on video height on smaller screens */
  }

  .responsive-video {
    height: 100%; /* Video height adapts to content */
    max-height: 100vh; /* Prevent overflow on small screens */
  }

  .content-container {
    align-items: center;
    text-align: center;
    padding: 24px;
  }
}
</style>
