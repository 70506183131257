<template>
  <div class="page-three-container">
    <h2 class="form-title">
      Select the project components that apply
    </h2>
    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <p>Please select all that apply:</p>
      <div class="form-group-selection-container">
        <div
          v-for="status in statuses"
          :key="status"
          :class="`form-group-selection ${isSelected(status) ? 'selected':''}`"
          @click="onSelectstatus(status)"
        >
          {{ status }}
        </div>
        <div class="form-group">
          <input
            id="other"
            v-model="otherText"
            type="text"
            placeholder="Others (please specify)"
            :style="`${otherText.length > 0 ? 'border: 1px solid var(--Neutrals-Gainsboro, transparent); outline: 2px solid var(--Neutrals-Gainsboro, #007aff);' : ''}`"
          >
        </div>
      </div>

      <div class="button-container">
        <button
          type="submit"
          :class="`submit-button ${!isFormValid ? 'disabled' : ''}`"
          :disabled="!isFormValid"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
  projectStatus:{
    type: Array,
    default: undefined
  }
});

const selectedStatus = ref([]);
const otherText = ref('');
const emit = defineEmits(['submit']);

onMounted(()=>{
  selectedStatus.value = props.projectStatus;
  const others = props.projectStatus.filter(item => !statuses.value.includes(item));
  if (others.length > 0) {
    otherText.value = others[0];
  }
});

const statuses = ref([
  'Website',
  'Mobile app, web app, PWA',
  'Content management system (CMS)',
  'Admin dashboard',
  'Server-side processing (e.g. Node.js, PHP)',
  'Cloud infrastructure (e.g AWS, Azure)',
  'Exploring options/Open to suggestions'
]);

const onSelectstatus = (status: string) => {
  if (selectedStatus.value.includes(status)) {
    selectedStatus.value = selectedStatus.value.filter(item => item !== status);
  }else{
    selectedStatus.value.push(status);
  }
};

function isSelected(status: string) {
  return selectedStatus.value.includes(status);
}

const isFormValid = computed(() => {
  return selectedStatus.value.length > 0;
});

const submitForm = () => {
  selectedStatus.value = selectedStatus.value.filter(item => statuses.value.includes(item));
  if (otherText.value.trim() !== '') {
    selectedStatus.value.push(otherText.value);
  }
  const formData = {
    project_status: selectedStatus.value
  };
  emit('submit', formData);
};
</script>

<style scoped>

.page-three-container{
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-three-container::-webkit-scrollbar {
  display: none;
}

.contact-form .user-information{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 48px;
}

.contact-form p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.form-group-selection-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.form-group-selection {
  background-color: #fff;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  padding: 16px 24px;
  margin: 0 3px;
  border-radius: 12px;
  cursor: pointer;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  transition: color 0.3s;
}

.form-group-selection.selected, .form-group input:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.form-group input {
  width: calc(100% - 6px);
  margin: 0 3px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 64px 0;
  width: 93px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

@media screen and (min-width: 1080px) {
  .form-group-selection{
    height: 64px;
  }
}

@media screen and (max-width: 768px) {
  .form-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .contact-form{
    width: 100%;
  }

  .contact-form p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .form-group-selection-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .form-group-selection{
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 12px 24px;
    border-radius: 10px;
  }

  .contact-form .user-information{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }

  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
  }

  .form-group input{
    height: 48px;
    border-radius: 10px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }
  .submit-button {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    width: 84px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }

}

</style>
