<template>
  <div class="big-brain-main-container">
    <div class="top-placeholder">
      <LottieAnimation
        ref="anim"
        :animation-data="BigBrainJSON"
        :auto-play="false"
        :loop="false"
        class="lottie-obj"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { LottieAnimation } from 'lottie-web-vue';
import BigBrainJSON from '../lottie/bigbrain.json';

gsap.registerPlugin(ScrollTrigger);

const anim = ref<any>(null);

onMounted(() => {

  let startPoint = 'top 70%';
  let endPoint = 'bottom 85%';

  gsap.to('.lottie-obj', {
    scrollTrigger: {
      trigger: '.lottie-obj',
      endTrigger: '.big-brain-main-container',
      start: startPoint,
      end: endPoint,
      scrub: true,
      // pin: true,
      onUpdate: (self) => {
        const tick = self.progress;
        if (anim.value) {
          anim.value.goToAndStop(tick * 100, true);
        }
      }
    }
  });
});
</script>


<style scoped>
.big-brain-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.top-placeholder {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.lottie-obj {
  width: 50%;
}

@media screen and (max-width: 820px) {
  .lottie-obj {
    width: 100%;
  }

  .big-brain-main-container {
    margin-bottom: 0;
  }
}

</style>

