<template>
  <div class="main-container">
    <div class="info-sections">
      <div class="info-section">
        <h4>{{ obj.tlDrTitle }}</h4>
        <div
          v-if="obj.tlDrUnderline"
          class="underline"
        />
        <p>{{ obj.tlDrContent }}</p>
      </div>
      <div class="info-section">
        <h4>{{ obj.whatWeDidTitle }}</h4>
        <div
          v-if="obj.whatWeDidUnderline"
          class="underline"
        />
        <ul>
          <li
            v-for="(item, index) in obj.whatWeDidItems"
            :key="index"
          >
            <img
              :src="$cdn('icons/icon_tick.svg')"
              alt="Checkmark"
              class="checkmark-icon"
            >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="info-section">
        <h4>{{ obj.categoryTitle }}</h4>
        <div
          v-if="obj.categoryUnderline"
          class="underline"
        />
        <div class="category-item">
          <img :src="$cdn('icons/icon_folder.svg')"><p>{{ obj.categoryContent }}</p>
        </div>
        <div class="category-item">
          <img :src="$cdn('icons/icon_tag.svg')"><p>{{ obj.tagContent }}</p>
        </div>

        <div
          class="category-item"
          :style="isMobile ? 'margin-top: 16px;' : 'margin-top: 20px;'"
        >
          <p><a @click="emit('show-all-orgs')">View all organisations</a> we've worked with.</p>
        </div>
      </div>
      <hr>
    </div>

  </div>

</template>


<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  categories:{
    type: Array,
    default: () => []
  },
  tags:{
    type: Array,
    default: () => []
  },
});

const emit = defineEmits(['show-all-orgs']);

const isMobile = computed(() => {
  return window.innerWidth <= 480;
});

const formattedCategories = computed(() => {
  // Check if the array has more than one item
  if (props.categories.length > 1) {
    let objs: string[] = [];
    props.categories.forEach((item: any) => {
      // Split each categories at '&', and trim spaces to clean up the entries
      const parts = item.split('&').map((part: any) => part.trim());
      objs = objs.concat(parts);
    });

    // Get the last element
    const last = objs.pop();
    // Join all elements except the last with a comma and space
    const allButLast = objs.join(', ');
    // Combine them with an "&"
    return `${allButLast} & ${last}`;
  } else {
    // If there's only one item, return it as is, assuming no '&' to split
    return props.categories.length === 1 ? props.categories[0] : '';
  }
});

const formattedTags = computed(() => {
  // Check if the array has more than one item
  if (props.tags.length > 1) {
    let objs: string[] = [];
    props.tags.forEach((item: any) => {
      // Split each tags at '&', and trim spaces to clean up the entries
      const parts = item.split('&').map((part: any) => part.trim());
      objs = objs.concat(parts);
    });

    // Get the last element
    const last = objs.pop();
    // Join all elements except the last with a comma and space
    const allButLast = objs.join(', ');
    // Combine them with an "&"
    return `${allButLast} & ${last}`;
  } else {
    // If there's only one item, return it as is, assuming no '&' to split
    return props.tags.length === 1 ? props.tags[0] : '';
  }
});


const process = (data: any) => {
  return {
    tlDrTitle: data.child.first_section.title,
    tlDrUnderline: true,
    tlDrContent: data.child.first_section.description,
    whatWeDidTitle: data.child.second_section.title,
    whatWeDidUnderline: true,
    whatWeDidItems: data.child.second_section.details.map((value: any) => value.detail),
    categoryTitle: data.child.third_section.title,
    categoryUnderline: true,
    categoryContent: (formattedCategories.value as any).replace(/^"(.+(?="$))"$/, '$1'),
    tagContent: (formattedTags.value as any).replace(/^"(.+(?="$))"$/, '$1'),
  };
};
const obj = computed(() => process(props.data));

onMounted(() => {
  fadeInEffect('.info-sections');
});

</script>


<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 80px;
  padding-left: 48px;
  padding-right: 48px;
}

.info-sections {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1240px;
  width: 100%;
  gap: 50px;
  padding-bottom: 48px;
  border-bottom: 1px solid #DCDCDC;
}

.info-section {
  flex-basis: calc(33.333% - 1rem);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
}

.info-section h4 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  margin-bottom: 20px;
}

.underline {
  height: 4px;
  width: 40px;
  background-color: #00CEB0;
  margin-bottom: 24px;
}

.info-section p,
.info-section ul {
  margin-top: 0;
  color: #242424;
}

.checkmark-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.info-section ul {
  list-style: none;
  padding: 0;
}

.info-section ul li {
  display: flex;
  align-items: center;
  color: #242424;
}

.category-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.category-item img{
  width: 18px;
  height: 18px;
}

.category-item a{
  color: #007AFF;
  cursor: pointer;
}

hr {
  display: none;
}

@media (max-width: 768px) {
  .info-sections {
    flex-direction: column;
  }

  .info-section {
    flex-basis: 100%;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 480px) {


  .main-container {
    padding: 0 24px;
  }

  .info-sections {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    padding-bottom: 0;
    gap: 16px;
  }

  .category-item {
    padding-bottom: 8px;
  }

  .info-section h4 {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 12px;
  }

  .underline {
    margin-bottom: 16px;
  }

  .info-section p,
  .info-section ul {
    font-family: 'Inter' sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #242424;
  }

  .info-section ul li {
    padding-bottom: 8px;
  }

  .checkmark-icon {
    width: 12px;
    height: 12px;
  }

  hr {
    display: flex;
  }
}
</style>
