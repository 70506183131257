<template>
  <div class="site-footer">
    <div class="footer-widgets footer-widgets-top">
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          MALAYSIA
        </h4>
        <div class="footer-widget">
          <p>E-03-08, Sunway Geo Avenue 2,<br>
            Jalan Lagoon Selatan, Sunway South Quay,<br>
            Bandar Sunway, 47500 Subang Jaya,<br>
            Selangor, Malaysia.</p>
        </div>
      </div>
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          SINGAPORE
        </h4>
        <div class="footer-widget">
          <p>231 Mountbatten Road<br>Block C Level 3<br>Singapore 397999.</p>
        </div>
      </div>

      <div class="footer-widget">
        <h4 class="footer-widget-title">
          CONTACT
        </h4>
        <ul>
          <li><a
            class="get-in-touch"
            @click="emit('get-in-touch')"
          >Get in touch</a></li>
          <li><a href="mailto:hello@alphapod.com">hello@alphapod.com</a></li>
          <li><a href="tel:+01154232300">(+60) 11 5423 2300</a></li>
        </ul>

      </div>
    </div>

    <hr class="footer-divider">

    <div class="footer-widgets footer-widgets-bottom">
      <div class="footer-widget footer-widget-align-right">
        <p>© {{ dayjs().format('YYYY') }} Alphapod Sdn. Bhd. All Rights Reserved</p>
        <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/cookie-policy">Cookie Policy</a></p>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { onMounted, ref }  from 'vue';

interface BlogMeta {
  show_see_more: boolean,
  items: Blog[]
}

interface Blog {
  title: string,
  permalink: string
}

interface Work {
  title: string,
  permalink: string
}

const emit = defineEmits(['get-in-touch']);

const works = ref<Work[]>([]);
const blogs = ref<BlogMeta>({show_see_more: false, items: []});

function shortenString(text: string): string {
  return text.length > 40 ? text.slice(0, 40) + '...' : text;
}

function fetchWorks() {
  axios.get('/wp-json/api/v1/footer-works')
    .then(response => {
      works.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

function fetchBlogs() {
  axios.get('/wp-json/api/v1/footer-blogs')
    .then(response => {
      blogs.value = response.data;
    })
    .catch(error => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

onMounted(() => {
  // fetchWorks();
  // fetchBlogs();
});

</script>

<style scoped>

h4 {
  color: #969696;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

p, li {
  color: #969696;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
}

.site-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px;
  width: 100%;
}

.footer-divider,
.footer-widgets{
  max-width: 1248px;
}

.get-in-touch{
  cursor: pointer;
}

.footer-widgets-bottom,
.footer-widgets-top{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}

.footer-spacer{
  height: 40px;
}

/* Glow effect on hover */
.site-footer a:hover {
  text-decoration: underline;
  transition: text-decoration 0.3s ease-out;
}

@media screen and (max-width: 1024px) {
  .footer-widgets{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 32px;
  }
}

@media screen and (max-width: 480px) {
  .site-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 24px;
    width: 100%;
  }

  .footer-widget-align-right,
  .footer-widgets{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
