<template>
  <div class="swiper-main-container">
    <div
      v-if="centerSlides"
      class="center-slide-container"
    >
      <swiper-slide
        v-for="(focus, index) in keyFocuses"
        :key="index"
      >
        <div class="key-focus-container">
          <h4>{{ focus.title }}</h4>
          <p>{{ focus.description }}</p>
          <img
            :src="$cdn(focus.image)"
            alt="Key focus image"
          >
        </div>
      </swiper-slide>
    </div>
    <swiper
      v-else
      :slides-per-view="1"
      :space-between="12"
      :slides-offset-before="0"
      :slides-offset-after="0"
      :center-insufficient-slides="false"
      :centered-slides="true"
      :breakpoints="{
        820: {
          spaceBetween: 24,
          slidesOffsetBefore: 80,
          slidesOffsetAfter: 80,
          slidesPerView: 'auto',
          centeredSlides: false,
          centerInsufficientSlides: true
        },
      }"
      class="mySwiper"
      style="width: 100vw; display: flex;"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(focus, index) in keyFocuses"
        :key="index"
      >
        <div class="key-focus-container">
          <h4>{{ focus.title }}</h4>
          <p>{{ focus.description }}</p>
          <img
            :src="$cdn(focus.image)"
            alt="Key focus image"
          >
        </div>
      </swiper-slide>
    </swiper>
    <img
      class="double-arrow-right"
      :class="{ 'hidden': swiperInstance?.isEnd }"
      :src="$cdn('icons/icon-double-arrow-right.svg')"
      alt="Double arrow right"
      @click="swiperInstance?.slideNext()"
    >
    <img
      class="double-arrow-left"
      :class="{ 'hidden': swiperInstance?.isBeginning }"
      :src="$cdn('icons/icon-double-arrow-left.svg')"
      alt="Double arrow right"
      @click="swiperInstance?.slidePrev()"
    >
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const swiperInstance = ref<SwiperInstance>();

const centerSlides = ref(true);

function handleOnResize() {
  if (window.innerWidth < 1920) {
    centerSlides.value = false;
  } else {
    centerSlides.value = true;
  }
}

const keyFocuses = [
  { title: 'Mobile', description: 'We craft engaging mobile experiences that blend beautiful design with powerful functionality across all major platforms.', image: 'dna/dna-key-focus-mobile.png' },
  { title: 'Immersive', description: 'We showcase your brand in immersive 3D environments, opening a new dimension for connecting with your products and services.', image: 'dna/dna-key-focus-immersive.png' },
  { title: 'AI', description: 'We harness the power of AI to create data-driven solutions that personalise user engagement and humanise the brand experience.', image: 'dna/dna-key-focus-AI.png' },
  { title: 'IoT', description: 'We seamlessly meld hardware and software platforms to enable automation, real-time data insights and enhanced user experiences.', image: 'dna/dna-key-focus-iot.png' }
];

const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

onMounted(() => {
  window.addEventListener('resize', handleOnResize);
  handleOnResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOnResize);
});

</script>

<style scoped>

@keyframes moveLeftRight {
  0%, 100% {
    transform: translateX(0) translateY(50%);
  }
  50% {
    transform: translateX(3px) translateY(50%);
  }
}

h4, p {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  margin-bottom: 24px;
  z-index: 1;
}

h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

p {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.swiper-main-container{
  position: relative;
}

.center-slide-container{
  display: flex;
  gap: 24px;
}

.key-focus-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 426px;
  height: 426px;
  padding: 32px;
  border: 1px solid #FFF;
}

.swiper-slide {
  width: 426px !important;
}

.double-arrow-right, .double-arrow-left {
  display: none;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 820px) {

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .swiper-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .key-focus-container {
    width: calc(100% - 48px);
  }

  .double-arrow-right,
  .double-arrow-left {
    display: block;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 1;
    animation: moveLeftRight 1s infinite alternate;
  }

  .double-arrow-right {
    right: 0;
  }

  .double-arrow-left {
    left: 0;
    animation-direction: alternate-reverse;
  }

}
</style>
