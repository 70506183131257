<template>
  <div
    ref="target"
    class="main-container"
  >
    <div
      ref="leftContainer"
      class="scroll-container left-container"
    >
      <template
        v-for="(content, index) in leftSection"
        :key="`left-${index}`"
      >
        <div
          v-if="content.type === 'image'"
          class="content-item"
        >
          <img
            :id="content.id"
            :src="$cdn(content.src)"
            :alt="content.alt"
          >
        </div>
        <div
          v-else-if="content.type === 'text'"
          class="content-item sticky-text"
        >
          <h2>
            {{ content.title }}
          </h2>
          <p
            v-for="(paragraph, pIndex) in content.description"
            :key="`left-text-${pIndex}`"
          >
            {{ paragraph }}
          </p>
          <div class="button-section">
            <a href="/careers/">
              <button
                class="button-join"
              >
                Join our team
              </button>
            </a>
            <button
              class="button-read"
              @click="onClickOurStory"
            >
              Read our story
            </button>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="!isMobile"
      ref="scrollContainer"
      class="scroll-container right-container"
    >
      <div
        ref="parallax"
        class="parallax-content"
      >
        <template
          v-for="(content, index) in rightSection"
          :key="`right-${index}`"
        >
          <div
            v-if="content.type === 'image'"
            class="content-item"
          >
            <img
              :id="content.id"
              :src="$cdn(content.src)"
              :alt="content.alt"
            >
          </div>
          <div
            v-if="content.type === 'text'"
            class="content-item sticky-text"
          >
            <h2>
              {{ content.title }}
            </h2>
            <p
              v-for="(paragraph, pIndex) in content.description"
              :key="`right-text-${pIndex}`"
            >
              {{ paragraph }}
            </p>
            <div class="button-section">
              <a href="/careers/">
                <div
                  class="button-join"
                >
                  Join our team
                </div>
              </a>
              <button
                class="button-read"
                @click="onClickOurStory"
              >
                Read our story
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <swiper
      v-else
      :grab-cursor="true"
      :centered-slides="true"
      :slides-per-view="`auto`"
      :pagination="false"
      :initial-slide="1"
      :space-between="14"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(content, index) in rightSection"
        :key="index"
      >
        <img
          v-if="content.type === 'image'"
          class="content-item"
          :src="$cdn(content.src)"
          :alt="content.alt"
        >
        <div
          v-else-if="content.type === 'text'"
          class="content-item sticky-text"
        >
          <h2>
            {{ content.title }}
          </h2>
          <p
            v-for="(paragraph, pIndex) in content.description"
            :key="`right-text-${pIndex}`"
          >
            {{ paragraph }}
          </p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>


<script setup lang="ts">

import { ref, onMounted, computed, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { fadeInEffect } from '../../support/helper';

import { uuid } from 'vue3-uuid';

gsap.registerPlugin(ScrollTrigger);

const scrollContainer = ref(null);

const emit = defineEmits(['openOurStory']);

const onClickOurStory = () => {
  emit('openOurStory');
};

const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      first_section: {
        children: [
          {
            acf_fc_layout: 'image',
            image: {
              url: 'about/img_our_culture-1.jpg',
              alt: 'Our culture 1'
            },
          },
          {
            acf_fc_layout: 'text',
            title: 'Our culture',
            descriptions: [
              {description: 'Our culture is something that we take great care to nurture and protect, and that’s why we’re so proud of our people and the chapters they write in our story!'}
            ]
          }
        ]
      },
      second_section: {
        children: [
          {
            acf_fc_layout: 'image',
            image: {
              url: 'about/img_our_culture-2.jpg',
              alt: 'Placeholder Image'
            },
          },
          {
            acf_fc_layout: 'image',
            image: {
              url: 'about/img_our_culture-3.jpg',
              alt: 'Placeholder Image'
            },
          },
          {
            acf_fc_layout: 'image',
            image: {
              url: 'about/img_our_culture-4.jpg',
              alt: 'Placeholder Image'
            },
          },
        ]
      }
    })
  }
});

const process = (data: any) => {
  if(data.acf_fc_layout === 'image') {
    return {
      type: data.acf_fc_layout,
      src: data.image.url,
      alt: data.image.alt,
      id: uuid.v4()
    };
  }else{
    return {
      type: data.acf_fc_layout,
      title: data.title,
      description: data.descriptions.map((item: any) => item.description)
    };
  }
};

const leftSection = computed(() => {
  return props.data.first_section.children.map(process);
});

const rightSection = computed(() => {
  return props.data.second_section.children.map(process);
});

const target = ref(null);
const parallax = ref(null);
const leftContainer = ref(null);

onMounted(() => {
  if (target.value && !isMobile.value){
    let value = 2581;
    if (window.innerWidth <= 1024) {
      value = 900;
    }
    const y = 0.1 * value;
    gsap.to(parallax.value, {
      y: y * -1,
      ease: 'none',
      scrollTrigger: {
        trigger: parallax.value,
        endTrigger: target.value,
        start: 'top 80%',
        end: '80% bottom',
        scrub: true,
      }
    });
    gsap.to(leftContainer.value, {
      y: y * 3,
      ease: 'none',
      scrollTrigger: {
        trigger: leftContainer.value,
        endTrigger: target.value,
        start: 'top 80%',
        end: 'bottom bottom',
        scrub: true,
      }
    });
  }
  fadeInEffect('.sticky-text');
});

</script>
<style scoped>
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  gap: 80px;
  height: 2520px;
}

.scroll-container {
  flex: 1;
  overflow: hidden;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.left-container{
  max-width: 600px;
  width: 100%;
  padding-top: 120px;
  margin-left: 32px;
}

.right-container{
  max-width: 600px;
  width: 100%;
  padding-top: 120px;
  margin-right: 32px;
}

.right-content {
  display: flex;
  flex-direction: column;
}

.content-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 32px;
}

.parallax-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.content-item h2{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  padding-bottom: 32px;
  padding-top: 120px;
}

.content-item p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 16px;
}

.button-section{
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.button-read {
  background-color: #fff;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-join{
  background-color: #007AFF;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.button-join:hover{
  opacity: 0.8;
}

.button-read:hover{
  background-color: #fafafa;
}


@media screen and (max-width: 820px) {
  .main-container {
    flex-direction: column;
    height: auto;
  }

  .mySwiper{
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-bottom: 16px;
  }

  .swiper-slide{
    width: 80vw !important;
    height: auto !important;
  }

  .scroll-container {
    width: 100%;
    overflow-y: none;
    max-height: none;
    margin: 24px 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 24px;
  }

  .content-item img {
    margin: 64px 0;
  }

  .left-container{
    margin: 0;
    padding: 0 24px;
  }

  .right-container{
    display: flex;
    flex-direction: row;
  }

  .parallax-content {
    height: fit-content;
  }

  .content-item h2{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px; /* 121.875% */
    letter-spacing: -0.75px;
    padding-bottom: 24px;
    padding-top: 0;
  }

  .content-item p{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    padding-bottom: 0;
  }

  .button-section{
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    gap: 12px;
  }

  .button-read {
    height: 48px;
    background-color: #fff;
    border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
    border-radius: 10px;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    padding: 0;
    width: 100%;
  }

  .button-join{
    height: 48px;
    background-color: #007AFF;
    border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
    border-radius: 10px;
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    text-align: center;
    padding: 0;
    width: 100%;
  }
}
</style>
