<template>
  <div class="layout-container">
    <header class="header">
      <h1>{{ obj.title }}</h1>
      <p class="subtitle">
        {{ obj.description }}
      </p>
    </header>
    <div class="content">
      <img
        :src="`${obj.headerImage && obj.headerImage.length > 0 ? obj.headerImage : $cdn('project/placeholder.svg')}`"
        :alt="obj.altImage"
        class="session-image"
      >
    </div>
  </div>
</template>


<script setup lang="ts">

import { computed } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
});

const process = (data: any) => {
  return {
    title: data.title,
    description: data.subtitle,
    headerImage: data.images.url,
    altImage: data.images.alt
  };
};

const obj = computed(() => process(props.data));
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header {
  text-align: start;
  max-width: 1096px;
  width: 100%;
  padding: 0 48px;
}

.header h1 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px; /* 100% */
  letter-spacing: -5px;
  padding-bottom: 32px;
}

.subtitle {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  padding-bottom: 80px;
}

.content {
  position: relative;
  width: 100%;
}

.session-image {
  width: 100%;
  display: block; /* Remove any space below the image */
}

.text-overlay {
  position: absolute;
  bottom: 48px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  max-width: 80%;
}

.text-overlay blockquote {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: 1.4;
}

@media screen and (max-width: 820px) {
  .header h1 {
    font-size: 48px;
    line-height: normal;
    letter-spacing: -1px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    margin-top: 0;
    padding: 0 24px;
  }
  .header h1 {
    align-self: stretch;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    padding-bottom: 8px;
  }

  .session-image {
    width: auto;
    height: 260px;
    object-fit: cover;
    display: block; /* Remove any space below the image */
  }
  .subtitle {
    align-self: stretch;
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }
}
</style>

