// @ts-expect-error it will have
import { createApp, ref } from 'vue/dist/vue.esm-bundler';
import '../styles/main.scss';

// Import the application's components, pages, and i18n messages
import Components from './components';
import Pages from './pages';
// import messages from './i18n';

// Support utilities
import { cdn } from './support/cdn';
// import { langRedirect } from './support/langRedirect';

// import { Vue as VueIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';

// Type definitions for i18n and custom properties
// These are used for TypeScript support, enhancing code autocompletion and error checking
// export type MessageLanguages = keyof typeof messages;
// export type MessageSchema = typeof messages['en-US'];

// hmm, can have its own d.ts
// declare module 'vue-i18n' {
//   export interface DefineLocaleMessage extends MessageSchema { }
//   export interface DefineDateTimeFormat { }
//   export interface DefineNumberFormat { }
// }

// Moved to global-functions.d.ts
// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $cdn: (filePath: string, preset?: string, useLocal?: boolean) => string;
//     $langRedirect: (path: string, external?: string) => string;
//   }
// }

// Initialize VueI18n for internationalization support
// const i18n = createI18n({
//   warnHtmlMessage: false,
//   legacy: false,
//   locale: 'en-US', // Set the initial locale
//   fallbackLocale: 'en-US', // Set fallback locale
//   messages // Import messages for different locales
// });

// Vue application initialization
const vueApp = createApp({
  setup() {
    // Example of reactive data reference
    const wordpressData = ref(window?.['__wordpress_data__']); // Access global data passed from WordPress

    return {
      wordpressData
    };
  }
});

// Register global components from a dynamically imported list
Object.entries(Components).forEach(([name, component]) => {
  vueApp.component(name, component);
});

// Register InlineSvg as a global component
// vueApp.component('inline-svg', InlineSvg);

// Register global pages (Vue components used as pages)
Object.entries(Pages).forEach(([name, component]) => {
  vueApp.component(name, component);
});

// Setup global properties for use within Vue components
// This includes a CDN helper and a language redirection helper
vueApp.config.globalProperties.$cdn = cdn;
// vueApp.config.globalProperties.$langRedirect = langRedirect;

//// Sentry setup
if (process.env.APP_ENV !== 'local') {
  Sentry.init({
    app: vueApp,
    dsn: process.env.SENTRY_DSN,
    debug: process.env.SENTRY_DEBUG === 'false',
    environment: process.env.APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
  });
}
// Install plugins (i18n for internationalization)
// vueApp.use(i18n);

// Mount the Vue application to an element with the ID 'vue-app'
vueApp.mount('#vue-app');
