<template>
  <div class="thank-you-container">
    <h2>
      Thank you.
    </h2>
    <p>Someone from our team will get back to you as soon as possible. Meanwhile, check out <a href="/work/">some of the projects we’ve done.</a></p>
  </div>
</template>

<script setup lang="ts">
</script>
<style scoped>
.thank-you-container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.thank-you-container h2{
  color: #242424;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 160px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -8px;
  width: 1024px;
}

.thank-you-container p{
  color: #242424;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 619px;
}

.thank-you-container a{
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

@media screen and (max-width: 1024px) {

  .thank-you-container {
    height: 50vh;
  }
  .thank-you-container h2{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -5px;
    width: auto;
    margin-bottom: 24px;
  }

  .thank-you-container p{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }

  .thank-you-container a{
    color: var(--Primary-Dodger-Blue, #007AFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-decoration-line: underline;
  }
}

</style>
