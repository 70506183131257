<template>
  <div class="main-container">
    <header :class="`header-container ${showMenu ? 'light' : ''}` ">
      <div class="sub-container">
        <div class="logo">
          <a href="/"><img
            :src="`${showMenu ? $cdn('logos/alphapod-logo-light.svg') : $cdn('logos/alphapod-logo-dark.svg')}`"
            alt="Logo"
            class="header-nav-logo"
          ></a>
        </div>
        <nav
          v-if="!isMobile"
          class="nav-menu hidden"
        >
          <ul>
            <li>
              <a @click="onClickAppbarMenu(1)">Services</a>
            </li>
            <li>
              <a @click="onClickAppbarMenu(2)">Work</a>
            </li>
            <li>
              <a @click="onClickAppbarMenu(3)">About</a>
            </li>
            <li> <a
              href="#"
              @click="getInTouch"
            >Get in Touch</a></li>
          </ul>
        </nav>
        <nav v-else>
          <img
            class="header-nav-icon hidden"
            :src="`${showMenu ? $cdn('icons/icon_close_navbar.svg') : $cdn('icons/button-menu.png')}`"
            alt="Navigation menu"
            @click="onClickNavIcon"
          >
        </nav>
      </div>
    </header>
    <div
      v-if="showMenu"
      class="mobile-menu-container hidden"
    >
      <div
        v-if="showMenu"
        class="mobile-menu-container"
      >
        <div class="menu-list-section">
          <a @click="onClickAppbarMenu(1)">Services</a>
          <a @click="onClickAppbarMenu(2)">Work</a>
          <a @click="onClickAppbarMenu(3)">About</a>
          <a
            href="#"
            @click="getInTouch"
          >Get in Touch</a>
        </div>
        <footer class="nav-footer">
          <p>
            Copyright © 2024 Alphapod Sdn. Bhd.
          </p>
          <p>
            All Rights Reserved
          </p>
          <p><a href="/privacy-policy/">Privacy Policy</a>|<a href="/cookie-policy/">Cookie Policy</a></p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
const emit = defineEmits(['getInTouch', 'onClickMenu']);

const isMobile = ref(false);
const showMenu = ref(false);
const onClickNavIcon = () => {
  if (isMobile.value) {
    showMenu.value = !showMenu.value;
  }
};

watch(showMenu, () => {
  if (showMenu.value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
});

const updateSize = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  updateSize();
  window.addEventListener('resize', updateSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSize);
});

const getInTouch = () => {
  showMenu.value = false;
  emit('getInTouch', {});
};

const onClickAppbarMenu = (index: number) => {
  showMenu.value = false;
  emit('onClickMenu', index);
};
</script>


<style scoped>

.hidden {
  display: none !important;
}

.header-container {
    display: flex;
    position: relative;
    align-items: center;
    background-color: transparent !important;
    justify-content: center;
    max-height: 120px;
    min-height: 120px;
    height: 100%;
    width: 100%;
    max-width: 3840px;
    z-index: 9999;
    transition: background-color 0.3s ease-in-out;
    padding: 0;
}

.sub-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 24px;
}

.header-container.light {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
}

.mobile-menu-container{
  display: none;
}

.header-container .logo {
  width: fit-content;
  padding: 10px 0;
}
.nav-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}
.nav-menu ul li {
  display: table-cell;
  position: relative;
  padding: 10px 32px;
}
.nav-menu a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  z-index: 999;
  display: inline-block;
  padding: 4px 0;
  position: relative;
  cursor: pointer;
}

.nav-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-menu a:hover:after {
  width: 100%;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .header-container{
    padding: 12px 24px;
    height: fit-content;
    min-height: 80px;
  }

  .sub-container{
    margin: 0;
  }

  .mobile-menu-container{
    position: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100vh;
    width: 100%;
    z-index: 999;
  }

  .mobile-menu-container a{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .header-nav-logo{
    max-width: 121.5px;
    height: auto;
  }

  .header-nav-icon{
    width: 32px;
    height: 32px;
  }
  .menu-list-section{
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-footer{
    padding-bottom: 24px;
  }

  .nav-footer p,
  .nav-footer a{
    margin: 0 16px;
    align-self: stretch;
    text-align: center;
    color: var(--Neutrals-Nobel, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
</style>
