<template>
  <div>
    <blank-screen v-show="showForm" />
    <Transition name="fade">
      <AppBarDark
        v-if="darkMode"
        :class="`${showForm ? 'hidden' : ''}`"
        @get-in-touch="showForm = true"
      />
      <AppBarLight
        v-else
        :class="`${showForm ? 'hidden' : ''}`"
        :initial-expand="true"
        @get-in-touch="showForm = true"
      />
    </Transition>

    <HeroVue />
    <WelcomeMessage
      id="target"
      ref="ref1"
    />
    <BuilderMessage ref="ref2" />
    <BigSmallMessage
      v-if="!endBigSmallAnimation"
      ref="ref3"
      @end-animation="endBigSmallAnimation = true"
    />
    <div v-else>
      <BigSmallMessageNoAnimation ref="ref4" />
      <ProjectShowcase
        ref="ref5"
        :dark-mode="true"
        :grid-items="showcase"
      />
      <ClientIconsDark
        ref="ref6"
        :data="works"
        @show-paperplane="resetPaperplaneKey"
        @on-change-list="resetPaperplaneKey"
      />
      <ContactUsFooter
        :key="paperplaneKey"
        ref="ref7"
        :show-paper-plane-animation="true"
        @open-form="handleFormRequest"
        @end-animation="endPaperPlaneAnimation = true"
      />
      <Footer
        v-show="endPaperPlaneAnimation"
        @get-in-touch="openForm"
      />
    </div>

    <!-- CONTACT US FORM -->
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import AppBarDark from '../components/general/AppBarDark.vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import HeroVue from '../components/home/Hero.vue';
import WelcomeMessage from '../components/home/WelcomeMessage.vue';
import BuilderMessage from '../components/home/BuilderMessage.vue';
import BigSmallMessage from '../components/home/BigSmallMessage.vue';
import BigSmallMessageNoAnimation from '../components/home/BigSmallMessageNoAnimation.vue';
import ProjectShowcase from '../components/project/ProjectShowcase.vue';
import ClientIconsDark from '../components/home/ClientIconsDark.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';
import Footer from '../components/general/Footer.vue';

import { uuid } from 'vue3-uuid';


const darkMode = ref(true);
const showForm = ref(false);
const endBigSmallAnimation = ref(false);

const endPaperPlaneAnimation = ref(false);
const paperplaneKey = ref(uuid.v4());

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const works = computed(() => {
  return props.wordpressData?.works_items;
});


const showcase = computed(() => {
  return props.wordpressData?.works_items.filter((item:any) => item.is_not_featured === false);
});

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

function resetPaperplaneKey() {
  if (!endPaperPlaneAnimation.value) {
    paperplaneKey.value = uuid.v4();
  }
}

gsap.registerPlugin(ScrollTrigger);
onMounted(() => {

  ScrollTrigger.create({
    trigger: '#target',
    start: 'top 16%',
    onEnter: () => {
      darkMode.value = false;
    },
    onLeaveBack: () => {
      darkMode.value = true;
    },
    markers: false,
  });
  if (props.wordpressData) {
    switch (props.wordpressData.query) {
      case 'contact-us':
        showForm.value = true;
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.pushState({}, document.title, urlWithoutParams);
        break;
      case 'job':
        const baseUrl = process.env.APP_URL;
        window.location.href = `${baseUrl}/careers/?goto=job-application`;
        break;
    }
  }
});


</script>

<style scoped>

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
