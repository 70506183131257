<template>
  <div>
    <blank-screen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <BlogLandingContent
      :tags="wordpressData.blog_tags"
      :tag="wordpressData.tag_data"
    />
    <ContactUsFooter
      @open-form="handleFormRequest"
    />
    <Footer
      @get-in-touch="openForm"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';

gsap.registerPlugin(ScrollTrigger);

import ScrollToPlugin from 'gsap/ScrollToPlugin';
import BlogLandingContent from '../components/blog/BlogLandingContent.vue';
gsap.registerPlugin(ScrollToPlugin);

const showForm = ref(false);

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const queryParams = ref('');

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  queryParams.value = params.get('filter') || '';
});

</script>

<style scoped>
.spacing {
  height: 280px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 180px;
  }
}
</style>
