<template>
  <div class="section">
    <div class="text-content">
      <h1>{{ title }}</h1>
      <p
        v-for="(description, index) in descriptions"
        :key="`description-${index}`"
      >
        {{ description }}</p>
    </div>
    <swiper
      :slides-per-view="slidesPerView"
      :loop="true"
      :space-between="pageBetween"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false,
      }"
      :prevent-interaction-on-transition="true"
      :free-mode="true"
      :speed="8000"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(block, index) in imageBlocks"
        :key="index"
      >
        <div
          v-if="block.type === 'single'"
          class="image-container"
        >
          <img
            :src="$cdn(block.images[0].src)"
            :alt="block.images[0].alt"
            class="image-large"
          >
        </div>
        <div
          v-if="block.type === 'double'"
          class="image-container double"
        >
          <img
            v-for="(image, i) in block.images"
            :key="`double-${index}-${i}`"
            :src="$cdn(image.src)"
            :alt="image.alt"
            class="image-small"
          >
        </div>
      </swiper-slide>
    </swiper>
  </div></template>

<script setup lang="ts">
import imageBlocks from '../assets/services-image-blocks.json';
import { onMounted, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';

// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const slidesPerView = ref(3);
const pageBetween = ref(34);

onMounted(() => {
  if (window.innerWidth <= 480) {
    slidesPerView.value = 1.5;
    pageBetween.value = 16;
  } else {
    slidesPerView.value = 3;
    pageBetween.value = 34;
  }
});

const title = ref('We make things so you can make magic 🪄');

const descriptions = ref([
  'We help you conceptualise, design, and build digital products that your users and customers love.',
]);
</script>

<style scoped>
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.bottom-text,
.text-content{
  max-width: 1000px;
  margin: 0 48px;
  padding-bottom: 120px;
}

.text-content h1 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -5px;
  margin-bottom: 64px;
}

.text-content p {
  color: var(--Neutrals-Dim-Grey, #646464);
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 16px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: stretch;
}

.image-container{
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.image-large{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small {
  width: 100%;
  height: 50%;
}

@media screen and (max-width: 1024px) {
  .text-content{
    margin: 64px 24px;
  }
  .text-content h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }

  .text-content p {
    color: var(--Neutrals-Dim-Grey, #646464);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 820px) {
  .text-content {
    padding: 0 80px;
    margin: 0;
    margin-top: 180px;
    margin-bottom: 64px;
  }

  .image-container.double {
    gap: 24px;
  }
}

@media screen and (max-width: 480px) {
  .text-content {
    padding: 0 24px;
  }

  .image-container.double {
    gap: 16px;
  }
}
</style>
