<template>
  <section class="main-container">
    <video
      :src="$cdn('https://res.cloudinary.com/alphapod/video/upload/v1725605054/alphapod_website/website_2024/assets/video/ap_dna_helix.h264.mp4')"
      autoplay
      loop
      muted
      playsinline
      class="responsive-video"
    >
      Your browser does not support the video tag.
    </video>
    <div class="gradient" />
    <div class="content-container">
      <h4 class="animate-about-section1">
        The Genesis of DNA: A Fusion of Innovation and Creativity
      </h4>
      <p
        v-for="(description, index) in descriptions"
        :key="'description-'+index"
        :class="`animate-about-section-${index}`"
        v-html="description"
      />
      <img
        class="animate-about-section3"
        :src="$cdn('dna/dna-about-venn-diagram.png')"
      >
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { fadeInEffect } from '../../support/helper';


onMounted(()=>{
  fadeInEffect('.animate-about-section1');
  descriptions.forEach((_, index) => {
    fadeInEffect('.animate-about-section-' + index);
  });
  fadeInEffect('.animate-about-section3');
});

const descriptions = [
  'In the ever-evolving landscape of digital experiences, two powerhouse agencies—Alphapod and DSTNCT—recognized an opportunity to redefine the future by merging their unique strengths. Alphapod, with its deep roots in mobile and web app development, and DSTNCT, a creative agency known for pushing the boundaries of brand storytelling, joined forces to create DNA.',
  'Alphapod has been a trailblazer in the digital realm, delivering cutting-edge solutions that blend technology and design. From its inception, Alphapod has focused on harnessing the power of technology to build apps that not only meet but anticipate the needs of users, ensuring seamless and engaging experiences. Their expertise in crafting user-centric mobile and web applications has made them a trusted partner for brands looking to innovate in the digital space.',
  'On the other hand, DSTNCT has carved a niche in the creative industry with its bold and unconventional approach to brand strategy and content creation. By understanding the cultural pulse and leveraging the latest in design thinking, DSTNCT has consistently delivered campaigns that resonate deeply with audiences. Their ability to tell compelling stories through visual and experiential mediums has set them apart as leaders in the creative field.',
  'Recognizing the potential for a synergistic collaboration, Alphapod and DSTNCT envisioned a new entity—DNA—designed to deliver holistic digital experiences that combine technical precision with creative flair. DNA stands as a testament to what’s possible when technical expertise meets imaginative storytelling, offering clients a comprehensive suite of services that bridge the gap between development and design.',
  'Through DNA, clients can expect a seamless integration of Alphapod\'s technological prowess with DSTNCT\'s creative vision, resulting in digital solutions that are not only functional but also memorable and impactful. This partnership marks the beginning of a new chapter, where innovation and creativity converge to shape the digital experiences of tomorrow.'
];

</script>

<style scoped>
h4 {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.25px;
}

p {
  color: #FAFAFA;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 32px; /* 160% */
}

.main-container {
  max-width: 3840px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; /* Take full viewport height */
}

.gradient {
  position: absolute;
  top: -2px;
  left: 0;
  width: 100vw;
  height: 105%;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 90%),
              linear-gradient(to bottom left, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 90%);
  z-index: 0;
}

.responsive-video {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%; /* Force the video to follow main container's height */
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: 0;
}

.content-container {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  z-index: 1;
  color: white;
  text-align: left;
  padding: 129px 0;
  gap: 32px;
  margin-top: -50%;
}

.animate-about-section3{
  margin-top: 50px;
}

@media screen and (max-width: 1024px) {
  .content-container {
    padding: 80px;
  }
}

@media (max-width: 820px) {

  h4 {
    font-size: 24px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .main-container {
    height: 100%; /* Allow height to be based on video height on smaller screens */
  }

  .responsive-video {
    height: 100vh;
  }

  .content-container {
    align-items: center;
    padding: 24px;
    margin-top: -120%;
    gap: 24px;
  }
}
</style>
