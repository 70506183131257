<template>
  <div class="gallery-container">
    <h1 class="gallery-title">
      {{ obj.title }}
    </h1>
    <swiper
      :slides-per-view="getScreenSize() == 'mobile' ? `auto` : 2"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false,
      }"
      :loop="true"
      :free-mode="true"
      :speed="8000"
      :prevent-interaction-on-transition="true"
      :pagination="false"
      :space-between="getScreenSize() == 'mobile' ? 16 : 34"
      class="mySwiper"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(block, index) in obj.imageBlocks"
        :key="index"
        @click="centerSlide(index)"
      >
        <img
          :src="block.src"
          :alt="block.alt"
        >
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore, { Swiper as SwiperType} from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import { fadeInEffect, getScreenSize } from '../../support/helper';

// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

onMounted(() => {
  fadeInEffect('.gallery-title');
  fadeInEffect('.mySwiper');
});

const process = (data: any) => {
  return {
    title: data.title,
    imageBlocks: data.images.map((item: any) => {
      return {
        src: item.image.url,
        alt: item.image.alt
      };
    })
  };
};

const obj = computed(() => process(props.data));

const mySwiperRef = ref<SwiperType>();

const onSwiper = (swiperInstance: SwiperType) => {
  mySwiperRef.value = swiperInstance;
};

// Method to center the slide
function centerSlide(index: number): void {
  mySwiperRef.value?.slideTo(index);
}

</script>

<style scoped>
.gallery-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
}

.gallery-title{
  color: #242424;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  max-width: 1040px;
  padding-top: 160px;
  padding-bottom: 80px;
  padding-left: 48px;
  padding-right: 48px;
}

.swiper-slide{
  height: auto !important;
}

img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 2;
}

.mySwiper{
  width: 100%;
  padding-bottom: 120px;
  object-fit: cover;
}

@media screen and (max-width: 820px) {
  .gallery-title{
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .mySwiper{
    padding-bottom: 64px;
  }

  .swiper-slide{
    width: 80vw !important;
    height: auto !important;
  }
}

</style>
