<template>
  <div
    ref="mainContainer"
    :class="`main-container` "
  >
    <div
      id="subContainer"
      class="sub-container"
    >
      <div
        :class="`big-small-title`"
      >
        <h1>Big, small, and everything in between.</h1>
      </div>
      <div
        ref="target"
        class="circle-container"
      >
        <svg
          class="small-circle"
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

        <svg
          class="medium-circle"
          viewBox="0 0 191 191"
        >
          <circle
            cx="95.5"
            cy="95.5"
            r="90"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

        <svg
          class="large-circle"
          viewBox="0 0 366 366"
        >
          <circle
            cx="183"
            cy="183"
            r="180"
            stroke="white"
            stroke-width="1"
            fill="white"
          />
        </svg>

      </div>
    </div>

  </div>
</template>


<script setup lang="ts">
</script>


<style scoped>
.main-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: fit-content;
  height: 100%;
  background-color: #000;
}

.sub-container{
  background-color: transparent;
  max-width: 1000px;
  min-height: fit-content;
  padding: 160px 24px;
}
.big-small-title{
  color: #fff;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
}

.circle-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: black 0.5s ease-in;
}

.small-circle {
  height: 24px;
  width: 24px;
}

.small-circle.frame {
  animation: bounceFillColor 0.5s ease-in-out;
}

.medium-circle {
  height: 191px;
  width: 191px;
}

.medium-circle.frame {
  animation: bounceFillColor 0.5s ease-in-out;
}

.large-circle {
  height: 366px;
  width: 366px;
}

@media screen and (max-width: 480px) {

  /* .main-container{
    height: 100%;
  } */
  .sub-container{
    background-color: transparent;
    padding: 80px 24px;
    transition: black 0.5s ease-in ;
  }
  .big-small-title{
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }

  .circle-container {
    padding-top: 64px;
  }

  .small-circle {
    height: 12px;
    width: 12px;
  }

  .medium-circle {
    height: 49px;
    width: 49px;
  }

  .large-circle {
    height: 130px;
    width: 130px;
  }
}

</style>
