<template>
  <div
    ref="formContainer"
    class="page-one-container"
  >
    <h2 class="form-title">
      Apply for a job at Alphapod
    </h2>

    <div class="position-information">
      <div class="position-description">
        <h3>Position applied</h3>
        <p>{{ form.position }}</p>
      </div>
      <div class="position-description">
        <h3>Department</h3>
        <p>{{ toTitleCase(form.department) }}</p>
      </div>
    </div>

    <form
      class="contact-form"
      @submit.prevent="submitForm"
    >
      <div class="user-information">
        <div class="form-group">
          <label for="name">Your name</label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            placeholder="Full name"
            autocomplete="name"
          >
          <span v-if="formSubmitted && !form.name">Name is required.</span>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="form.email"
            type="email"
            placeholder="mail@example.com"
            autocomplete="email"
          >
          <span v-if="form.email.length > 0 && !validEmail">Please enter a valid email address.</span>
        </div>
        <!-- phone number -->
        <div class="form-group">
          <label for="phone number">Contact Number</label>
          <PhoneNumberDropdown
            :form-container="formContainer"
            :country-code="form.countryCode"
            :phone-number="form.phoneNumber"
            @phone-number-valid="validPhoneNumber = $event"
            @update-phone-number="form.phoneNumber = $event"
            @update-country-code="form.countryCode = $event"
          />
        </div>
      </div>
      <div class="button-container">
        <button
          type="submit"
          :class="`submit-button ${!isFormValid ? 'disabled' : ''}`"
          :disabled="!isFormValid"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, ref } from 'vue';
import PhoneNumberDropdown from '../general/PhoneNumberDropdown.vue';
const emit = defineEmits(['submit']);

const props = defineProps({
  position: {
    type: String,
    required: true,
    default:'Software Developer',
  },
  department: {
    type: String,
    required: true,
    default: 'Engineering',
  },
  name: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
  phoneNumber: {
    type: String,
    default: '',
  },
  countryCode: {
    type: String,
    default: '+60',
  }
});

function toTitleCase(str: string) {
  return str.toLowerCase().split(' ').map(function(word, index) {
    // Always capitalize the first word and the rest except for specific small words
    if (word && (index === 0 || !['and', 'the', 'of', 'in', 'on', 'at', 'with', 'a', 'an', 'but', 'or', 'for', 'nor'].includes(word))) {
      return word[0].toUpperCase() + word.substr(1);
    }
    return word;
  }).join(' ');
}

const form = reactive({
  name: props.name || '',
  email: props.email || '',
  position: props.position || '',
  department: props.department || '',
  phoneNumber: props.phoneNumber || '',
  countryCode: props.countryCode || '+60',
});

const formSubmitted = ref(false);
const formContainer = ref(undefined);

const validPhoneNumber = ref(true);

const validEmail = computed(() => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(form.email);
});

const isFormValid = computed(() => {
  return form.name && validEmail.value && validPhoneNumber.value && form.phoneNumber;
});

const submitForm = () => {
  if (form.name && validEmail.value) {
    emit('submit', form);
  }
};
</script>

<style scoped>

.page-one-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-one-container::-webkit-scrollbar {
  display: none;
}

.contact-form .user-information{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.position-information {
  display: flex;
  margin-bottom: 56px;
  gap: 20px;
}

.position-description {
  flex: 1 1 50%;
}

.form-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
  margin-bottom: 64px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.position-description h3,
.form-group label {
  display: block;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

.position-description p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
}

.form-group input {
  width: calc(100% - 6px);
  margin: 0 3px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  outline: none;
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.form-group span {
  color: #DC4664;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 12px;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50%;
  padding: 16px 24px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  outline: none;
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 16px 10px;
}

.form-group input:focus, .form-group select:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.submit-button {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 64px 0;
  margin-top: 32px;
  width: 93px;
  height: 64px;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  background-color: #007AFF;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.8;
}

.submit-button.disabled {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {

  .position-information {
    display: flex;
    margin-bottom: 32px;
    flex-direction: column;
    gap: 32px;
  }

  .form-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .contact-form{
    width: 100%;
  }

  .contact-form .user-information{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
  }

  .position-description h3,
  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 8px;
  }

  .position-description p{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  .form-group {
    margin-bottom: 16px;
  }

  .form-group input,
  .form-group select {
    height: 48px;
    border-radius: 10px;
    border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    background-size: 12px 12px;
  }

  .button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 80px;
    width: 100%;
  }
  .submit-button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    margin-top: 8px;
    height: 48px;
    width: 84px;
    border-radius: 10px;
  }

  .submit-button.disabled {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 820px) {
  .phone-number-container{
    display: flex;
    gap: 12px;
  }

  .form-group span {
    font-size: 14px;
    line-height: 28px;
  }

  .form-group input{
    padding: 12px 24px;
  }
}
</style>
