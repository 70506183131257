/**
 * This function also available globally
 *
 * Usage:
 *
 * <img :src="$cdn('img/image.png')">
 */
export const cdn = (filePath: string, useLocal: boolean = false): string => {

  if (/^https?:\/\//.test(filePath)) {
    return filePath; // Return the filePath itself if it's a URL
  }

  if (process.env.APP_ENV === 'local' || useLocal) {
    return process.env.APP_URL + '/wp-content/themes/ap_vue_base_theme/assets/' + filePath;
  }
  return 'https://' + process.env.AWS_S3_DELIVERY_DOMAIN_OR_CLOUDFRONT + '/assets/' + filePath;
};
