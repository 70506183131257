<template>
  <div class="main-container">
    <div
      v-if="content"
      class="sub-container"
    >
      <div
        v-for="(obj, index) in content"
        :key="index"
        class="content"
      >
        <div
          v-if="obj.acf_fc_layout === 'text'"
          class="content-text"
        >
          <template
            v-for="(item, i) in obj.layout"
            :key="`text-${i}`"
          >
            <h1
              v-if="item.acf_fc_layout === 'h1'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h2
              v-else-if="item.acf_fc_layout === 'h2'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h3
              v-else-if="item.acf_fc_layout === 'h3'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h4
              v-else-if="item.acf_fc_layout === 'h4'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <div
              v-else-if="item.acf_fc_layout === 'p'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <div
              v-else-if="item.acf_fc_layout === 'editor'"
              class="text-editor"
              v-html="parser(item.value)"
            />
            <b
              v-else-if="item.acf_fc_layout === 'b'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <ul
              v-else-if="item.acf_fc_layout === 'bullet_list'"
              :style="{ 'padding-bottom': (isMobile ? item.bottom_padding_mobile : item.bottom_padding) + 'px' }"
            >
              <li
                v-for="(li, j) in item.lists"
                :key="j"
                :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
                v-html="li.value"
              />
            </ul>
            <ol
              v-else-if="item.acf_fc_layout === 'number_list'"
              :style="{ 'padding-bottom': (isMobile ? item.bottom_padding_mobile : item.bottom_padding) + 'px' }"
            >
              <li
                v-for="(li, j) in item.lists"
                :key="j"
                :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
                v-html="li.value"
              />
            </ol>
          </template>
        </div>
        <img
          v-if="obj.acf_fc_layout === 'image'"
          :src="obj.value.url"
          :alt="obj.value.alt"
          class="content-image"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';

interface ContentObject {
  acf_fc_layout: string;
  value: any;
  layout: any[]
}

defineProps({
  content: {
    type: Array as () => ContentObject[],
    default: undefined
  }
});

function parser(text: string): string {
  return text.replace(/<p>&nbsp;<\/p>/g, '<p style="line-height: 8px;">&nbsp;</p>');
}


const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

</script>

<style scoped>

@import './style.scss';

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  padding: 0 48px;
}

.content-image {
  width: 100%;
  height: auto;
  padding: 120px 0;
}

.content-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #242424;
  font-family: 'Inter' sans-serif;
  text-align: left;
}

.content-text h1{
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
}

.content-text h2{
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
}

.content-text h3{
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.content-text h4{
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.content-text:deep(p){
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text:deep(a){
  color: #007AFF;
  text-decoration: underline;
}
.content-text b{
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text ul{
  list-style-type: disc;
  padding-left: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text ol{
  list-style-type: decimal;
  padding-left: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 0;
    padding: 0 24px;
  }

  .content-image {
    padding: 64px 0;
  }

  .content-text h1{
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -3px;
  }

  .content-text h2{
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
  }

  .content-text h3{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .content-text h4{
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }

  .content-text:deep(p){
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text b{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text ul{
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text ol{
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}
</style>
