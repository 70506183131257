<template>
  <Dropdown
    ref="dropdownRef"
    :manual="true"
    @visible-change="onVisibleChange"
  >
    <template #trigger>
      <button
        type="button"
        class="dropdown-trigger"
        @click="onClickDropdown"
      >
        <div style="display: flex; justify-content: space-between;align-items: center;">
          <span>{{ dropdownSelectIndex >= 0 ? options[dropdownSelectIndex] : initialValue }}</span>
          <img
            class="dropdown-caret"
            :src="$cdn('icons/icon_caret_down.svg')"
          >
        </div>
      </button>
    </template>
    <div
      class="dropdown-content"
      :style="dropdownStyle"
    >
      <div
        v-for="(option, index) in options"
        :key="`option-${index}`"
      >
        <button
          type="button"
          class="dropdown-item"
          :class="{ 'active': dropdownSelectIndex === index }"
          @click="onSelectDropdownItem(option)"
        >
          <div style="display: flex; justify-content: space-between;">
            <span>{{ option }}</span>
            <img
              v-if="dropdownSelectIndex === index"
              :src="$cdn('icons/icon_check_white.svg')"
            >
          </div>
        </button>
      </div>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from 'v-dropdown';
import { onMounted, ref } from 'vue';

const dropdownRef = ref(null);
const dropdownSelectIndex = ref(-1);

const emit = defineEmits(['select-dropdown-item']);
const props = defineProps({
  formContainer: {
    type: Object,
    default: undefined,
  },
  options: {
    type: Array,
    required: true
  },
  initialValue: {
    type: String,
    default: 'Please select'
  }
});

onMounted(() => {
  if (props.options.includes(props.initialValue)) {
    dropdownSelectIndex.value = props.options.indexOf(props.initialValue);
  }

  dropdownStyle.value = {
    width: dropdownRef.value?.offsetWidth + 'px',
  };
});

function onClickDropdown(_:any) {
  if (dropdownRef.value) {
    (dropdownRef.value as any).display();
  }
}

function onSelectDropdownItem(value: any) {
  dropdownSelectIndex.value = props.options.indexOf(value);
  emit('select-dropdown-item', value);
  if (dropdownRef.value) {
    (dropdownRef.value as any).close();
  }
}

function handleScroll() {
  if (dropdownRef.value) {
    (dropdownRef.value as any).close();
  }
}

const dropdownStyle = ref({
  width: '100%',
});

function onVisibleChange(visible: boolean) {
  if (visible) {
    if (props.formContainer){
      props.formContainer.addEventListener('scroll', handleScroll);
    }else{
      window.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('keydown', handleKeyEvent);
  } else {
    if (props.formContainer){
      props.formContainer.removeEventListener('scroll', handleScroll);
    }else{
      window.removeEventListener('scroll', handleScroll);
    }
    window.removeEventListener('keydown', handleKeyEvent);
  }
}

// Function to handle keyboard events for the country code dropdown
function handleKeyEvent(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowDown':
      event.preventDefault();
      if (dropdownSelectIndex.value === props.options.length - 1) {
        return;
      }
      dropdownSelectIndex.value = (dropdownSelectIndex.value + 1) % props.options.length;
      scrollToActiveItem(event.key);
      break;
    case 'ArrowUp':
      event.preventDefault();
      if (dropdownSelectIndex.value === 0) {
        return;
      }
      dropdownSelectIndex.value = (dropdownSelectIndex.value - 1 + props.options.length) % props.options.length;
      scrollToActiveItem(event.key);
      break;
    case 'Enter':
      event.preventDefault();
      onSelectDropdownItem(props.options[dropdownSelectIndex.value]);
      break;
  }
}

// Function to scroll to the active item in the dropdown
const scrollToActiveItem = (key: any) => {
  const dropdown = document.querySelector('.dropdown-content');
  const activeItem = document.querySelector('.dropdown-item.active');

  if (dropdown && activeItem) {
    const dropdownRect = dropdown.getBoundingClientRect();
    const activeRect = activeItem.getBoundingClientRect();

    const difference = activeRect.top - dropdownRect.top;
    if (difference > 128 && key === 'ArrowDown') {
      dropdown.scrollTop += 32;
    }else if (difference < 32 && key === 'ArrowUp') {
      dropdown.scrollTop -= 32;
    }
  }
};

</script>

<style scoped>
.v-dropdown-trigger {
  width: 100%;
}

.dropdown-trigger {
  width: 100%;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  outline: none;
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.dropdown-caret{
  width: 16px;
  height: 16px;
}

.dropdown-content{
  max-height: 250px;
  min-width: 500px;
  overflow-y: auto;
  padding: 8px;
}

.dropdown-item{
  width: 100%;
  padding: 4px 8px;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.dropdown-item.active, .dropdown-item:hover {
  background-color: #007AFF;
  color: #FFF;
  border-radius: 6px;
}

@media screen and (max-width: 832px) {
  .dropdown-trigger {
    height: 48px;
    border-radius: 10px;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 12px 24px;
  }

  .dropdown-item {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .dropdown-caret{
    width: 12px;
    height: 12px;
  }

  .dropdown-content{
    max-height: 200px;
    min-width: 85vw;
    overflow-y: auto;
  }
}

</style>
