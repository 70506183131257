<template>
  <div class="layout-container">
    <div class="close-button-container">
      <img
        :src="$cdn('icons/icon_close.svg')"
        alt="Close button"
        class="close-button"
        @click="$emit('close')"
      >
    </div>
    <div class="inner-container">
      <!-- gallery view mobile -->
      <div v-if="isMobile">
        <Swiper
          slides-per-view="auto"
          :free-mode="true"
          space-between="8"
          :slides-offset-before="24"
          :slides-offset-after="24"
        >
          <SwiperSlide
            v-for="(category, index) in visibleCategories"
            :key="`category-${index}`"
            class="category-button"
            :class="{ 'is-selected': selectedCategory === category }"
            @click="handleSelectCategory(category)"
          >
            {{ category }}
          </SwiperSlide>
          <SwiperSlide
            v-if="showMoreButton"
            class="category-button"
            @click="toggleVisibility"
          >
            {{ exceedTag }}
          </SwiperSlide>
        </Swiper>
      </div>
      <!-- gallery view desktop -->
      <div
        v-else
        class="categories"
      >
        <!-- tab -->
        <div
          v-for="(category, index) in visibleCategories"
          :key="`category-${index}`"
          class="category-button"
          :class="{ 'is-selected': selectedCategory === category }"
          @click="handleSelectCategory(category)"
        >
          {{ category }}
        </div>
        <!-- Button to toggle visibility -->
        <div
          v-if="showMoreButton"
          class="category-button"
          @click="toggleVisibility"
        >
          {{ exceedTag }}
        </div>

      </div>
      <!-- grid display -->
      <div class="brands-grid">
        <TransitionGroup>
          <div
            v-for="brand in filteredBrands"
            :key="`${brand.id}`"
            class="brand"
          >
            <img
              :src="brand.img"
              :alt="brand.alt"
            >
          </div>
        </TransitionGroup>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { ref, computed, type Ref, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/vue';

import { uuid } from 'vue3-uuid';

gsap.registerPlugin(ScrollTrigger);
interface DataItem {
  title: string;
  slug: string;
  categories?: string[];
  tags: string[];
  logo: {
    url: string;
    alt: string;
  };
}

const emit = defineEmits(['close']);

const isMobile = computed(() => {
  return window.innerWidth <= 480;
});

onMounted(() => {
  const elements = ['.inner-container .title', '.inner-container .subtitle', '.inner-container .categories', '.inner-container .brands-grid'];
  elements.forEach((selector) => {
    gsap.fromTo(selector, {
      opacity: 0,
    }, {
      opacity: 1,
      scrollTrigger: {
        trigger: selector,
        start: 'top 75%',
        toggleActions: 'play none none none',
      }
    });
  });
});

interface ProcessedBrand {
  name: string;
  category: string[];
  img: string;
  alt: string;
  slug: string;
  id: string;
}

const props = defineProps<{
  data: DataItem[];
}>();

const process = (value: DataItem): ProcessedBrand => {
  return {
    name: value.title,
    category: value.tags || ['Other'],
    img: value.logo.url,
    alt: value.logo.alt,
    slug: value.slug,
    id: '',
  };
};

const brands: Ref<ProcessedBrand[]> = computed(() => props.data.filter((item) => item.logo).map(process));
const categories = computed(() => {
  // Assuming `brands` is reactive
  const allCategories = brands.value.flatMap(brand => brand.category);
  const uniqueCategories = new Set(allCategories);
  return ['All', ...Array.from(uniqueCategories)];
});

const exceedNum = 4;

// const isTagExceeded = ref(false);

const selectedCategory = ref('All');

// This computed property now calculates if the "more" button should be shown
const showMoreButton = computed(() => categories.value.length > exceedNum);

// New computed property to control visible categories
const visibleCategories = computed(() => {
  if (!showAll.value) {
    return categories.value.slice(0, exceedNum);
  }
  return categories.value;
});

// New reactive property to toggle showing all categories
const showAll = ref(false);

const toggleVisibility = () => {
  showAll.value = !showAll.value;
  if (!showAll.value) {
    selectedCategory.value = 'All';
  }
};

function handleSelectCategory(category: string) {
  selectedCategory.value = category;
}

// Adjusted exceedTag to better handle showing remaining items count
const exceedTag = computed(() => {
  const exceed = categories.value.length - exceedNum;
  if (exceed > 0 && !showAll.value) {
    return `${exceed}+ more`;
  } else if (showAll.value) {
    return 'Show less';
  }
  return '';
});


// Filter brands based on the selected category
const filteredBrands = computed(() => {
  // First filter the brands based on the selected category
  let result = brands.value;
  if (selectedCategory.value !== 'All') {
    result = brands.value.filter(brand => brand.category.includes(selectedCategory.value));
  }
  // Then sort the resulting array alphabetically by brand name
  result.sort((a, b) => a.slug.localeCompare(b.slug));
  //append unique id to each of the brands in the list
  result.forEach((brand) => {
    brand.id = uuid.v4();
  });
  return result;
});

</script>


<style scoped>

.v-enter-from, .v-leave-to {
  opacity: 0;
  transform: scale(0);
}
.v-enter-to, .v-leave-from {
  opacity: 1;
  transform: scale(1);
}

.v-enter-active,
.v-leave-active,
.v-move {
  transition: all 0.7s;
}
.v-leave-active { position: absolute; }

.layout-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: #FAFAFA; /* Assuming a dark theme like in the screenshot */
  text-align: center;
  z-index: 9999;
  overflow-y: auto;
}

.close-button-container{
  display: flex;
  width: 100%;
  padding: 66px;
  justify-content: flex-end;
}
.close-button{
  width: 40px;
  cursor: pointer;
}

.inner-container {
  width: 100%;
  max-width: 1280px;
  padding-top: 149px;
  padding-bottom: 224px;
}

.title {
  color: #fff;
  width: 100%;
  height: auto;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 130px;
  letter-spacing: -5px;
  padding-bottom: 64px;
  padding-left: 128px;
  padding-right: 128px;
  text-align: start;
}

.subtitle {
  color: #fff;
  width: 100%;
  height: auto;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 120px;
  padding-left: 128px;
  padding-right: 128px;
  text-align: start;
}

.categories {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding-bottom: 64px;
  padding-left: 89.5px;
  padding-right: 89.5px;
}

.category-button {
  display: inline-block;
  border-radius: 12px;
  background-color: #F0F0F0;
  color: #242424; /* White text color */
  border: none;
  cursor: pointer;
  outline: none; /* Removes the outline to match the design */
  transition: background-color 0.3s; /* Smooth transition for background color */
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  border-radius: 12px;
  padding: 16px 32px;
  text-align: left;
  width: fit-content;
}

.category-button.is-selected,
.category-button.is-selected:hover {
  background: #242424;
  color: #fff;
}

/* Optionally, change the hover state for additional user feedback */
.category-button:hover {
  background-color: #555; /* Lighter background on hover */
  color: #fff;
}


.brands-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.brand {
  display: flex;
  max-width: 256px;
  max-height: 120px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.brand img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

@media screen and (max-width: 820px) {

  .inner-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -2px;
    padding: 32px 80px;
  }

  .subtitle {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 0 80px 80px 80px;
  }

}

@media screen and (max-width: 480px) {
  .title {
    padding: 32px 24px;
  }

  .subtitle {
    padding: 0 24px 80px 24px;
  }

  .inner-container {
    padding-top: 64px;
    padding-bottom: 120px;
  }

  .brand {
    max-width: 114px;
    max-height: 53.15px;
  }


  .brands-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 0 24px;
  }

  .swiper {
    width: 100%;
    margin-bottom: 32px;
  }

  .categories {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    height: auto;
    padding-bottom: 32px;
    margin-left: 0;
    gap: 16px;
    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Style to hide scrollbar on Webkit browsers like Chrome and Safari */
  .categories::-webkit-scrollbar {
    display: none;
  }

  .close-button-container{
    padding: 24px;
  }

  .close-button {
    width: 32px;
    height: 32px;
  }

  .category-button {
    display: inline-flex; /* Use inline-flex to allow content-based width */
    align-items: center; /* Center content vertically */
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center; /* Center text if you prefer, but it's optional */
    padding: 12px 24px;
    white-space: nowrap; /* Prevent text wrapping */
  }
}
</style>
