<template>
  <div class="main-container">
    <header class="main-title">
      Unbelievable, super cool, outrageous, and amazing.
    </header>
    <swiper
      :slides-per-view="slidesPerView"
      :space-between="pageBetween"
      :loop="true"
      :autoplay="{
        delay: 1,
        disableOnInteraction: false,
      }"
      :prevent-interaction-on-transition="true"
      :free-mode="true"
      :speed="8000"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(block, index) in imageBlocks"
        :key="index"
      >
        <div
          v-if="block.type === 'single'"
          class="image-container"
        >
          <img
            :src="$cdn(block.images[0].src)"
            :alt="block.images[0].alt"
            class="image-large"
          >
        </div>
        <div
          v-if="block.type === 'double'"
          class="image-container double"
        >
          <img
            v-for="(image, i) in block.images"
            :key="`double-${index}-${i}`"
            :src="$cdn(image.src)"
            :alt="image.alt"
            class="image-small"
          >
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import imageBlocks from '../assets/career-image-blocks.json';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';

// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const slidesPerView = ref(3);
const pageBetween = ref(34);

onMounted(() => {
  if (window.innerWidth <= 480) {
    slidesPerView.value = 1.5;
    pageBetween.value = 16;
  } else {
    slidesPerView.value = 3;
    pageBetween.value = 34;
  }
});

</script>

<style scoped>

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-title{
  color: #242424;
  max-width: 1000px;
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -5px;
  margin: 0 48px;
  padding-bottom: 120px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: stretch;
}

.image-container{
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.image-large, .image-small {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small{
  height: 50%;
}


@media screen and (max-width: 820px) {
  .main-title{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
    padding: 0;
    margin: 180px 80px 64px 80px;
  }

  .image-container.double {
    gap: 16px;
  }

  .mySwiper {
    height: 260px;
  }
}

@media screen and (max-width: 480px) {
  .main-title{
    margin: 180px 24px 64px 24px;
  }

}
</style>
