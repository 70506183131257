<template>
  <div class="contact-form-container">
    <div class="close-button-container">
      <img
        :src="$cdn('icons/icon_close.svg')"
        alt="Close button"
        class="close-button"
        @click="closeJobForm"
      >
    </div>
    <div class="main-contact-form">
      <div :class="`progress-bar ${progress === 4 ? 'progress-bar-complete' : ''}`">
        <div
          v-for="index in totalSteps"
          :key="index"
          :class="{'progress': true, 'progress-current': index <= progress}"
          @click="onClickProgress(index)"
        />
      </div>
      <JobApplicationPageOne
        v-if="showPage === 1"
        :position="position"
        :department="department"
        :name="dataModel.name"
        :email="dataModel.email"
        :phone-number="dataModel.contact_number"
        :country-code="dataModel.contact_number_country_code"
        @submit="handlePageOneSubmit"
      />
      <JobApplicationPageTwo
        v-if="showPage === 2"
        :heard-about-us="dataModel.heard_about_us"
        @submit="handlePageTwoSubmit"
      />
      <JobApplicationPageThree
        v-if="showPage === 3"
        :applied-reason="dataModel.apply_reason"
        :resume-file="dataModel.resume_file"
        :supporting-file="dataModel.supporting_file"
        :is-sending-email="isSendingEmail"
        @submit="handlePageThreeSubmit"
        @data-changes="handlePageThreeChanges"
      />
      <JobApplicationThankYou v-if="showPage === 4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';

import JobApplicationPageOne from './JobApplicationPageOne.vue';
import JobApplicationPageTwo from './JobApplicationPageTwo.vue';
import JobApplicationPageThree from './JobApplicationPageThree.vue';
import JobApplicationThankYou from './JobApplicationThankYou.vue';

import { constructJobApplicationMessage, sendEmailWithAttachments } from '../../support/helper';

const emit = defineEmits(['closeJobForm']);

const props = defineProps({
  position:{
    type: String,
    required: true,
    default:'Software Developer',
  },
  department: {
    type: String,
    required: true,
    default: 'Engineering',
  }
});

const dataModel = ref({
  name: '',
  email: '',
  contact_number: '',
  contact_number_country_code: '+60',
  position_applied: props.position || '',
  department_applied: props.department || '',
  heard_about_us: '',
  apply_reason: '',
  resume_file: undefined,
  supporting_file: undefined,
});

const isSendingEmail = ref(false);

onMounted(() => {
  document.addEventListener('keydown', handleEscapeKey);
});

const totalSteps = 3; // Total number of steps in the progress bar
const progress = ref(1);
const showPage = ref(1);

const onClickProgress = (index: number) => {
  if (index <= progress.value) {
    showPage.value = index;
    progress.value = index;
  }
};

function  handleEscapeKey(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    closeJobForm();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscapeKey);
});

const closeJobForm = () => {
  if (dataModel.value.name.length > 0) {
    // Using confirm to ask the user if they want to close the form
    const userConfirmed = confirm('All data will be lost. Are you sure you want to exit the form?');
    if (userConfirmed) {
      // User clicked "Yes", proceed to emit the close event
      emit('closeJobForm');
    }
    // If user clicks "No", the dialog is dismissed and nothing happens
  } else {
    // If the form is empty, directly emit the close event
    emit('closeJobForm');
  }
};

function handlePageOneSubmit(payload: any){
  dataModel.value.name = payload.name;
  dataModel.value.email = payload.email;
  dataModel.value.contact_number = payload.phoneNumber;
  dataModel.value.contact_number_country_code = payload.countryCode;
  showPage.value = 2;
  progress.value = 2;
}

function handlePageTwoSubmit(payload: any){
  dataModel.value.heard_about_us = payload.heard_about_us;
  showPage.value = 3;
  progress.value = 3;
}

function handlePageThreeSubmit(payload: any){
  const message = constructJobApplicationMessage(dataModel.value);
  isSendingEmail.value = true;
  const attachments = <any>[];
  if (dataModel.value.resume_file) {
    attachments.push(dataModel.value.resume_file);
  }
  if (dataModel.value.supporting_file) {
    attachments.push(dataModel.value.supporting_file);
  }
  sendEmailWithAttachments(payload.recaptchaToken, message, attachments, true, dataModel.value.position_applied).then(() => {
    // Email sent successfully, redirect the user
    showPage.value = 4;
    progress.value = 4;
    dataModel.value.name = '';
    dataModel.value.email = '';
    dataModel.value.position_applied = '';
    dataModel.value.department_applied = '';
    dataModel.value.heard_about_us = '';
    dataModel.value.apply_reason = '';
    dataModel.value.resume_file = undefined;
    dataModel.value.supporting_file = undefined;

    isSendingEmail.value = false;
  })
    .catch((error) => {
      // Handle the error (show an error message to the user, log the error, etc.)
      console.log(error);
      alert('An error occurred while sending the email. Please try again later.');
      isSendingEmail.value = false;
    });;
}

function handlePageThreeChanges(payload: any){
  dataModel.value.apply_reason = payload.apply_reason;
  if (payload.resume_file) {
    dataModel.value.resume_file = payload.resume_file;
  }
  if (payload.supporting_file) {
    dataModel.value.supporting_file = payload.supporting_file;
  }
}

</script>

<style scoped>

.contact-form-container{
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: #FAFAFA;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding: 64px 64px 0 64px;
}

.main-contact-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.close-button-container{
  display: flex;
  justify-content: flex-end;
}
.close-button{
  width: 40;
  height: 40;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-bottom: 32px;
}

.progress {
  width: 64px;
  height: 6px;
  border-radius: 14px;
  background-color: #DCDCDC;
  cursor: not-allowed;
}

.progress-current {
  background-color: #007AFF;
  cursor: pointer;
}

.progress-bar-complete{
  display: none;
}

@media screen and (max-width: 820px) {
  .contact-form-container{
    padding: 24px;
  }

  .main-contact-form {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 0vh;
  }

  .progress-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 12px;
    margin-bottom: 32px;
  }
  .progress {
    width: 100%;
    height: 6px;
    border-radius: 14px;
    background-color: #DCDCDC;
    cursor: not-allowed;
  }

  .progress-current {
    background-color: #007AFF;
    cursor: pointer;
  }
  .progress-bar {
    margin-left: 0px;
    margin-right: 0px;
  }
  .close-button{
    width: 32px;
    height: 32px;
    margin-bottom: 32px;
    cursor: pointer;
  }
}

</style>
