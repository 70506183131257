// import he from 'he';
import axios from 'axios';
import { sendEmailUrl } from './constant';

export const getScreenSize = () => {
  const width = window.innerWidth;
  if (width < 480) {
    return 'mobile';
  } else if (width >= 480 && width <= 768) {
    return 'tablet';
  } else if (width > 768) {
    return 'desktop';
  }

  return null;
};

// export const removeHtmlString = (htmlString: string) => {
//   const unescapedHtml = he.decode(htmlString);
//   const plainText = unescapedHtml.replace(/<[^>]*>/g, '');

//   return plainText;
// };

export const arraysAreEqual = (array1: Array<any>, array2: Array<any>) => {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

//https://stackoverflow.com/a/49448231
type BROWSER_ENUM =
  'EDGE' |
  'INTERNET_EXPLORER' |
  'FIRE_FOX' |
  'OPERA' |
  'UC_BROWSER' |
  'SAMSUNG_BROWSER' |
  'CHROME' |
  'SAFARI' |
  'UNKNOWN';


const testUserAgent = (regexp: RegExp): boolean => regexp.test(window.navigator.userAgent);

export function detectBrowser(): BROWSER_ENUM {
  switch (true) {
    case testUserAgent(/edg/i): return 'EDGE';
    case testUserAgent(/trident/i): return 'INTERNET_EXPLORER';
    case testUserAgent(/firefox|fxios/i): return 'FIRE_FOX';
    case testUserAgent(/opr\//i): return 'OPERA';
    case testUserAgent(/ucbrowser/i): return 'UC_BROWSER';
    case testUserAgent(/samsungbrowser/i): return 'SAMSUNG_BROWSER';
    case testUserAgent(/chrome|chromium|crios/i): return 'CHROME';
    case testUserAgent(/safari/i): return 'SAFARI';
    default: return 'UNKNOWN';
  }
}

export const checkLang = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return langParam == 'zh-hans' ? 'zh-hans' : 'en';
};


// EMAIL HELPER

export const sendEmailWithAttachments = async (message: string, fileInputElements: HTMLInputElement | HTMLInputElement[] | undefined, isJobForm: boolean = false, subject?: string): Promise<void> => {
  try {
    const formData = new FormData();
    // Append email data to formData
    if (isJobForm) {
      const title = subject ? `Job Application - ${subject}` : 'Application from job application form';
      // Application from job form
      formData.append('to', 'email1');
      formData.append('subject', title);
    } else {
      const title = subject ? `${subject} x Alphapod` : 'Application from contact us form';
      // Application from contact us form
      formData.append('to', 'email2');
      formData.append('subject', title);
    }

    formData.append('message', message);

    // Append files to formData if available
    if (fileInputElements) {
      if (!Array.isArray(fileInputElements)) {
        fileInputElements = [fileInputElements];
      }
      fileInputElements.forEach(input => {
        const files = input.files;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append('attachments[]', files[i], files[i].name);
          }
        }
      });
    }

    // Make POST request to custom WordPress API endpoint with formData
    const response = await axios.post(sendEmailUrl(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle response
    if (response.data.success) {
      console.log('Email sent successfully');
    } else {
      console.error('Failed to send email');
    }
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

/**
 * Constructs an email message from project enquiry data.
 * @param {Object} enquiryData - The project enquiry data.
 * @returns {string} The formatted email message.
 */
export const constructEmailMessage = (enquiryData: any) => {
  const {
    name,
    email,
    company,
    organization,
    role,
    contact_number_country_code,
    contact_number,
    contact_purpose,
    project_status,
    budget,
    ideal_go_live_date,
    project_description,
    project_description2,
    attachment_file,
  } = enquiryData;

  let contact = '—';
  if (contact_number) {
    contact = `${contact_number_country_code}${contact_number}`;
  }

  let projectStatus = '<ul style="padding-left: 0; margin-top: 0; margin-bottom: 0; list-style-position: inside;">';
  if (project_status) {
    project_status.forEach((status: string) => {
      projectStatus += `<li style="margin-left: 0;">${status}</li>`;
    });
  }
  projectStatus += '</ul>';

  // Construct the email message using HTML tags
  const emailMessage = `
    <p><b>Name</b><br>${name}, ${role} at ${organization}</p>
    <p><b>Email</b><br>${email}</p>
    <p><b>Contact number</b><br>${contact}</p>
    <p><b>Organisation type</b><br>${company}</p>
    <p><b>Contact purpose</b><br>${contact_purpose}</p>
    <p><b>Project component(s)</b><br>${projectStatus}</p>
    <p><b>Budget</b><br>${budget}</p>
    <p><b>Go-live date</b><br>${ideal_go_live_date}</p>
    <p><b>Project description</b><br>${project_description}</p>
    <p><b>Additional notes</b><br>${project_description2 ? project_description2 : '—'}</p>
    <p><b>Attachment</b><br>${attachment_file ? 'Yes' : 'No'}</p>
  `;

  return emailMessage;
};


/**
 * This function sanitizes input by escaping HTML characters, ensuring safety in environments
 * where no HTML tags are intended, such as plain text emails. This prevents security risks,
 * like injection attacks. Note: Previously, this function led to unintended 'garbage data'
 * appearing in the email body due to over-sanitization.
 */
function escapeHtml(text: string) {
  const map: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };
  return text.replace(/[&<>"']/g, (m) => map[m]);
}



export const constructJobApplicationMessage = (enquiryData: any) => {
  const {
    name,
    email,
    contact_number,
    contact_number_country_code,
    position_applied,
    department_applied,
    heard_about_us,
    apply_reason,

  } = enquiryData;

  let contactNumber = '-';
  if (contact_number) {
    contactNumber = `${contact_number_country_code}${contact_number}`;
  }

  // Construct the email message
  const emailMessage = `
  <p><b>Name</b><br>${name}</p>
  <p><b>Position applied</b><br>${position_applied}</p>
  <p><b>Email</b><br>${email}</p>
  <p><b>Contact number</b><br>${contactNumber}</p>
  <p><b>How did you hear about Alphapod?</b><br>${heard_about_us}</p>
  <p><b>Reason for applying</b><br>${apply_reason}</p>
  <p><b>Attachment</b><br>Yes</p>
  `;

  return emailMessage;
};

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const fadeInEffect = (element: string) => {
  const selector = document.querySelector(element);
  if (selector) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      selector,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: selector,
          start: 'top 75%',
          toggleActions: 'play none none none',
        },
      }
    );
  }
};
