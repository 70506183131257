<template>
  <div class="section">
    <div class="text-content">
      <h1>We don’t build apps. Or websites.</h1>
      <p>Not just. Even if that is the client’s brief to us. We put on our entrepreneur and user hats, challenge convention, and ask the difficult questions in order to deliver real value to businesses.</p>
      <br>
      <p>We unlock potential by enabling ideas to be turned into commercially viable products.</p>
      <br>
      <p>We win when our partners win.</p>
    </div>
    <swiper
      :slides-per-view="slidesPerView"
      :space-between="pageBetween"
      :loop="true"
      :autoplay="{
        delay: 0,
        disableOnInteraction: false,
      }"
      :free-mode="true"
      :speed="8000"
      :prevent-interaction-on-transition="true"
      :parallax="true"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(block, index) in imageBlocks"
        :key="index"
      >
        <div
          v-if="block.type === 'single'"
          class="image-container"
        >
          <img
            :src="$cdn(block.images[0].src)"
            :alt="block.images[0].alt"
            class="image-large"
          >
        </div>
        <div
          v-if="block.type === 'double'"
          class="image-container double"
        >
          <img
            v-for="(image, i) in block.images"
            :key="`double-${index}-${i}`"
            :src="$cdn(image.src)"
            :alt="image.alt"
            class="image-small"
          >
        </div>
      </swiper-slide>
    </swiper>

    <div class="bottom-text">
      <p>Since 2009 we've been helping businesses of all shapes and sizes build their product and brands. Our design and development services have become an inseparable part of their success.</p>
      <br>
      <p>So no, we don’t just build apps, or websites.</p>
      <h2>We build businesses.</h2>
    </div>
  </div>
</template>

<script setup lang="ts">

import imageBlocks from '../assets/home-image-blocks.json';
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SwiperCore from 'swiper';
// Import Swiper core and required modules
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted } from 'vue';
gsap.registerPlugin(ScrollTrigger);
// Install Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const slidesPerView = ref(3);
const pageBetween = ref(34);
onMounted(()=>{
  if (window.innerWidth <= 480) {
    slidesPerView.value = 1.5;
    pageBetween.value = 16;
  } else {
    slidesPerView.value = 3;
    pageBetween.value = 34;
  }
});

onMounted(()=>{
  gsap.fromTo('.text-content', {
    opacity: 0,
  }, {
    opacity: 1,
    scrollTrigger: {
      trigger: '.text-content',
      start: 'top 80%',
    },
  });

  gsap.fromTo('.bottom-text', {
    opacity: 0,
  }, {
    opacity: 1,
    scrollTrigger: {
      trigger: '.bottom-text',
      start: 'top 80%',
    },
  });
});
</script>

<style scoped>
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  background-color: #fff;
}

.bottom-text,
.text-content{
  max-width: 1000px;
  margin: 120px 24px;
}

.text-content h1 {
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.text-content p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: stretch;
}

.image-container{
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  gap: 34px;
}

.image-large {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small{
  width: 100%;
  height: 50%;
}

.bottom-text p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.bottom-text h2 {
  margin-top: 64px;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}


@media screen and (max-width: 820px) {

  .bottom-text,
  .text-content{
    margin: 64px 80px;
  }

  .text-content h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }
  .bottom-text h2{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .image-container.double {
    gap: 16px;
  }

  .bottom-text p,
  .text-content p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .image-container{
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .bottom-text,
  .text-content{
    margin: 64px 24px;
  }
}
</style>
