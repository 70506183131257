<template>
  <div
    v-if="content"
    class="bg-container"
  >
    <div class="sub-container">
      <div
        class="left-section"
        :style="{ backgroundColor: content.color }"
      >
        <div
          v-if="!isMobile"
          class="content-container"
          :style="{ backgroundColor: content.color }"
        >
          <h2>NEXT UP</h2>
          <h1>{{ content.title }}</h1>
          <p class="description">
            {{ content.description }}
          </p>
          <div class="buttons-container">
            <a :href="`${baseUrl}/work/${content.readCaseUrl}`">
              <div
                class="button-read"
              >
                Read case study
              </div>
            </a>
            <a href="/work/">
              <div
                class="button-all"
              >
                See all work
              </div>
            </a>
          </div>
        </div>
        <div
          v-else
          class="image-and-overlay"
        >
          <img
            class="project-img"
            :src="content.image ? content.image : $cdn('showcase/shane-willard-ministries-footer.svg')"
            :alt="content.altImage"
          >
          <div
            class="gradient-overlay"
            :style="gradientStyle"
          />
        </div>
      </div>
      <div
        class="right-section"
        :style="{ backgroundColor: content.color }"
      >
        <div
          v-if="!isMobile"
          class="image-and-overlay"
        >
          <img
            class="project-img"
            :src="content.image ? content.image : $cdn('showcase/shane-willard-ministries-footer.svg')"
            :alt="content.altImage"
          >
          <div
            class="gradient-overlay"
            :style="gradientStyle"
          />
        </div>
        <div
          v-else
          class="content-container"
          :style="{ backgroundColor: content.color }"
        >
          <h2>NEXT UP</h2>
          <h1>{{ content.title }}</h1>
          <p class="description">
            {{ content.description }}
          </p>
          <div class="buttons-container">
            <a :href="`${baseUrl}/work/${content.readCaseUrl}`">
              <div
                class="button-read"
              >
                Read case study
              </div>
            </a>
            <a href="/work/">
              <div
                class="button-all"
              >
                See all work
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import  axios  from 'axios';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  id: String
});

const content = ref();
const baseUrl = process.env.APP_URL;
const isMobile = ref(false);

const gradientStyle = computed(() => ({
  background: `linear-gradient(${isMobile.value ? 'to top' : 'to right'}, ${content.value.color} 15%, transparent 85%)`,
}));

const processData = (value: any): any => {
  return {
    title: value.title,
    description: value.description,
    image: value.image ? value.banner.url : null,
    altImage: value.image ? value.banner.alt : 'showcase project',
    color: value.feature_color,
    readCaseUrl: value.slug,
  };
};

function onResize() {
  isMobile.value = window.innerWidth <= 1024;
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  fetch();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

function fetch() {
  axios.get('/wp-json/api/v1/works-showcase', {
    params: {
      exclude_post_id: props.id,
    }
  })
    .then(response => {
      content.value = processData(response.data);
    });
}

</script>

<style scoped>

.bg-container {
  width: 100%;
}

.sub-container {
  display: flex;
  height: 612px;
  width: 100%;
}

.image-and-overlay {
  position: relative;
  flex-shrink: 1;
}

.project-img {
  display: flex;
  object-fit: cover;
  min-height: 612px;
  height: 100%;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}
.left-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.right-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.content-container {
  flex-grow: 1;
  max-width: 542px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.content-container h2 {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
  text-transform: uppercase;
  opacity: 0.75; /* Slightly transparent */
  margin-bottom: 8px; /* Space below the subheading */
}

.content-container h1 {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 125% */
  letter-spacing: -3px;
  margin-bottom: 16px; /* Space below the title */
}

.description {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  margin-bottom: 64px;
}

.buttons-container {
  display: flex; /* Aligns buttons in a row */
  gap: 16px; /* Space between buttons */
}

.button-read{
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: #FFF;
  color: #242424;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  transition: opacity 0.3s ease; /* Smooth transition for hover effect */
}
.button-all {
  padding: 1rem 32px; /* Padding inside buttons */
  color: inherit; /* Inherits white color from parent */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  border: 2px solid rgba(255, 255, 255, 0.50);
}

.button-read:hover{
  opacity: 0.8;
}

.button-all:hover {
  background-color: rgba(255, 255, 255, 0.2) ;
}

@media screen and (max-width: 1024px) {

  .sub-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    padding-top: 24px;
    color: white;
  }

  .left-section{
    justify-content: center;
  }

  .right-section {
    justify-content: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .content-container h1 {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }



  .content-container h2 {
    color: var(--Neutrals-Nobel, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .description {
    color: var(--Neutrals-Nobel, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .button-read{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
  }
  .button-all {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    border-radius: 10px;
  }

  .project-img {
    display: flex;
    height: auto;
    min-height: 219px;

  }

  .gradient-overlay {
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 30%, transparent 70%);
  }
}
</style>
