<template>
  <div class="main-container">
    <div class="sub-container">
      <h1>Privacy Policy</h1>
      <div class="grey-text">
        This Privacy Policy covers the privacy of visitors to Alphapod.com, and other websites under the Alphapod.com domain (“Websites”) and applies to Alphapod Sdn. Bhd.
      </div>
      <br>
      <div class="grey-text">
        We may update this Privacy Policy periodically and will post any changes on this page.
      </div>
      <div class="medium-gap" />
      <hr>
      <div class="medium-gap" />
      <h2>Overview</h2>
      <br>
      <p>Your privacy matters to us and we only collect data we need, and only for our own use. We do not sell your personal information to third parties.</p>
      <div class="medium-gap" />
      <h2>What We Collect and Why</h2>
      <div class="small-gap" />
      <h3>Personal information</h3>
      <p>Personal Information refers to any information that you voluntarily submit to us and that identifies you personally, including contact information, such as your name, e-mail address, company name, address, phone number, and other information about yourself, or your business, or any information such as, but not limited, to ideas and/or plans related to your business.</p>
      <br>
      <p>The Personal Information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
      <br>
      <p>You are free to visit our Websites without providing any information about yourself. We may collect navigational information about you which refers to information about your computer and your visits to the Websites such as your IP address, geographical location, browser type, referral source, length of visit and pages viewed. Please see the “Use of navigation information” section below.</p>
      <div class="medium-gap" />
      <h3>Logs</h3>
      <p>When you view content provided by us, we automatically collect information about your computer hardware and software. This information can include your IP address, browser type, domain names, internet service provider (ISP), the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, clickstream data, access times and referring website addresses. This information is used by us to provide general statistics regarding use of the Websites. For these purposes, we do link this automatically-collected data to other Personal Information such as name, email address, address, and phone number.</p>
      <div class="medium-gap" />
      <h3>Information about children</h3>
      <p>Our Websites are not intended for or targeted at children under 16, and we do not knowingly or intentionally collect personal information about children under 16. If you believe that we have collected personal information about a child under 16, please contact us at <a href="mailto:hello+privacy@alphapod.com">hello+privacy@alphapod.com</a>, so that we may delete the information.</p>
      <div class="medium-gap" />
      <h2>How We Use Your Information</h2>
      <div class="small-gap" />
      <h3>Personal information</h3>
      <p>We use the information we collect only in compliance with this Privacy Policy.</p>
      <br>
      <p>We do not sell your Personal Information to any third party.</p>
      <br>
      <p>In addition to the uses identified elsewhere in this Privacy Policy, we may use your Personal Information to:</p>
      <br>
      <ul>
        <li>improve your browsing experience by personalising the Websites</li>
        <li>send information to you which we think may be of interest to you by email, or other means and send you marketing communications relating to our business</li>
        <li>promote use of our services to you and share promotional and information content with you in accordance with your communication preferences</li>
        <li>provide other companies with statistical information about our users - but this information will not be used to identify any individual user and will only be shared on an aggregate and de-identified basis</li>
        <li>send information to you regarding changes to our Privacy Policy (including the Cookie Policy), or other legal agreements</li>
        <li>investigate and help prevent security issues and abuse</li>
        <li>meet legal requirements</li>
      </ul>
      <div class="medium-gap" />
      <h3>Use of navigational information</h3>
      <p>We use Navigational Information to operate and improve the Websites and to provide you with a better experience. We may also use Navigational Information alone or in combination with Personal Information to provide you with personalised information about our products and services. Navigational Information, such as IP address, may be used to approximate your location. For example, we may use your approximate location to provide content that has been translated or send emails at certain times using your local time zone.</p>
      <div class="medium-gap" />
      <h3>External websites</h3>
      <p>Our Websites provide links to other websites of third parties. We do not control, and are not responsible for, the content or practices of these other websites. Our provision of such links does not constitute our endorsement of these other websites, their content, their owners, or their practices. This Privacy Policy does not apply to these other websites, which are subject to any privacy and other policies they may have.</p>
      <div class="medium-gap" />
      <h3>Retention of personal information</h3>
      <p>How long we keep information we collect about you depends on the type of information, as described in further detail below. After such time, we will either delete or anonymize your information or, if this is not possible, then we will securely store your information and isolate it from any further use until deletion is possible. </p>
      <br>
      <p>We retain Personal Information that you provide to us where we have an ongoing legitimate business need to do so (for example, as needed to comply with our legal obligations, resolve disputes and enforce our agreements). </p>
      <br>
      <p>If you have elected to receive marketing communications from us, we retain information about your marketing preferences for a reasonable period of time from the date you last expressed interest in our content, products, or services.</p>
      <div class="medium-gap" />
      <h2>How We Share Your Information</h2>
      <div class="small-gap" />
      <h3>Service providers</h3>
      <p>We employ other third-party service providers to provide services on our behalf to visitors to our Websites and may need to share your information with them to provide information, products or services to you. Examples may include removing repetitive information from prospect lists, analyzing data or performing statistical analysis, providing marketing assistance, supplementing the information you provide us in order to provide you with better service, and providing customer service or support. These service providers are prohibited from using your Personal Information except for these purposes, and they are required to maintain the confidentiality of your information.</p>
      <div class="medium-gap" />
      <h3>Corporate events</h3>
      <p>If we (or our assets) are acquired by another company, whether by merger, acquisition, bankruptcy or otherwise, that company would receive all information gathered by us on the Websites. In this event, you will be notified via email and/or a prominent notice on our website, of any change in ownership, uses of your Personal Information, and choices you may have regarding your Personal Information.</p>
      <div class="medium-gap" />
      <h2>The Use of Cookies</h2>
      <div class="small-gap" />
      <p>We use cookies or similar technologies (such as web beacons and JavaScript) to analyse trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. To find out more about how we use cookies on our Websites and how to manage your cookie preferences please see our <a href="/cookie-policy/">Cookie Policy</a>.</p>
      <div class="medium-gap" />
      <h2>Your Data Protection Rights</h2>
      <div class="small-gap" />
      <p>You have the following data protection rights:</p>
      <br>
      <ul>
        <li>You can request access, correction, updates or deletion of your personal information</li>
        <li>You can object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information</li>
        <li>If we have collected and process your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent</li>
        <li>You have the right to complain to a data protection authority about our collection and use of your personal information</li>
      </ul>
      <br>
      <p>To exercise any of these rights, please contact us at <a href="mailto:hello+privacy@alphapod.com">hello+privacy@alphapod.com</a>.</p>
      <div class="medium-gap" />
      <!-- <h3>To unsubscribe from our communications</h3>
      <p>You may unsubscribe from our marketing communications by clicking on the “unsubscribe” link located on the bottom of our e-mails, updating your communication preferences, or by emailing us at <a href="mailto:hello+privacy@alphapod.com">hello+privacy@alphapod.com</a>.</p>
      <div class="medium-gap" /> -->
      <h2>Data Protection Officer</h2>
      <div class="small-gap" />
      <p>If you have any questions about this Privacy Policy or our privacy practices, or if you have a disability and need to access this notice in a different format, please contact us at <a href="mailto:hello+privacy@alphapod.com">hello+privacy@alphapod.com</a> or by mail at:</p>
      <div class="medium-gap" />
      <p>Head of Privacy</p>
      <p>Alphapod Sdn. Bhd.</p>
      <p>E-03-08, Sunway Geo Avenue 2,</p>
      <p>Jalan Lagoon Selatan,</p>
      <p>Sunway South Quay,</p>
      <p>Bandar Sunway,</p>
      <p>47500 Subang Jaya, Selangor,</p>
      <p>Malaysia.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>


.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.medium-gap{
  height: 64px;
}

.small-gap{
  height: 32px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 24px;
  padding-bottom: 160px;
}

.sub-container h1{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.sub-container h2{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.sub-container h3{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.sub-container p, li{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

ul {
  margin-left: 20px;
  list-style-type: disc;
}

.sub-container a{
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-decoration-line: underline;
}

.grey-text{
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 64px 80px;
  }

  .medium-gap{
    height: 48px;
  }

  .small-gap{
    height: 16px;
  }

  .sub-container h1{
    font-family: 'Inter' sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: left;
    margin-bottom: 24px;
  }

  .sub-container h2{
    font-family: 'Inter' sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .sub-container h3{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .sub-container p, li{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  ul {
    margin-left: 20px;
    list-style-type: disc;
  }

  .sub-container a{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .grey-text{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    margin: 64px 24px;
  }
}

</style>
