<template>
  <div
    ref="counter"
    class="counter"
  >
    <div
      v-show="animationEnd"
    >
      {{ value }}
    </div>
    <div
      v-for="(stack, index) in digitStacks"
      v-show="!animationEnd"
      :key="index"
      class="digit-container"
      :class="{ scrollable: stack.length > 1 }"
    >
      <div
        v-if="hasDigits"
        class="scroll-content"
      >
        <span
          v-for="num in stack"
          :key="num"
        >
          {{ num }}
        </span>
      </div>
      <div v-else>
        {{ stack }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Imports and dependencies
import { ref, onMounted, computed } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { uuid } from 'vue3-uuid';

// Register GSAP's ScrollTrigger plugin for scroll animations
gsap.registerPlugin(ScrollTrigger);

const animationEnd = ref(false);

// Define props for external configuration
const props = defineProps({
  value: {
    type: String,
    default: '$1,234,567.89', // Default value if none provided
  }
});

// Reference to the main counter element for GSAP animation targeting
const counter = ref(null);
const noAnimationTextRef = ref(uuid.v4());
// Computed property to generate digit stacks for animation
const hasDigits = computed(() => /\d/.test(props.value));
const digitStacks = computed(() => {
  if (!hasDigits.value) {
    return [props.value];
  }
  return props.value.split('').map(char => {
    if (/\d/.test(char)) {  // Check if character is a digit
      const maxDigit = parseInt(char);
      // Create a stack of numbers from '-' up to the current digit
      return ['-', ...Array.from({ length: maxDigit + 1 }, (_, i) => i)];
    }
    // Return non-digit characters as they are
    return [char];
  });
});

let animationEndStatus = false;

// Setup animations once the component is mounted
onMounted(() => {
  const digitContainers = (counter as any).value.querySelectorAll('.digit-container.scrollable .scroll-content');

  digitContainers.forEach((container: any) => {
    const digits = container.querySelectorAll('span');
    const height = digits[0].offsetHeight;
    const totalScrollHeight = height * digits.length;

    // GSAP animation for scrolling each digit into view
    gsap.to(container, {
      y: -totalScrollHeight + height,  // Scroll to display the last digit
      ease: 'ease',
      duration: 1.5,
      scrollTrigger: {
        trigger: container.parentNode,
        start: 'top 80%',
      },
      onStart: () => {
        setTimeout(() => {
          animationEnd.value = true;
        }, 1500);
      }
    });

  });



});
</script>


<style scoped>

.counter {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.5px;
  margin-bottom: 24px;
}

.digit-container {
  min-width: 10px;
  overflow: hidden;
  height: 56px;
}

.scroll-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .counter {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.75px;
    margin-bottom: 32px;
  }

  .digit-container {
    height: 39px;
  }
}
</style>

